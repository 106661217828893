import { useEffect, useState } from 'react';
import styles from './Contagem.module.css'
import Celacontagem from '../../components/Celacontagem'
import { Button, Table } from 'reactstrap';
import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { FiPrinter } from "react-icons/fi";
import logo from '../../assets/img/logo.png';
import excel from '../../assets/img/excel.png';
import * as XLSX from "xlsx";
import * as FileSaver from "file-saver";


const Contagem = ({galerias, atualizarDadosMovimento2}) => {
 
  const [pagina, setPagina] = useState(6)
  const [ativaBotao, setAtivaBotao] = useState(6)
  const data = new Date();
  const dia = String(data.getDate()).padStart(2, '0'); // Adiciona um zero à esquerda se for necessário
  const mes = String(data.getMonth() + 1).padStart(2, '0'); // Os meses começam do zero, então adicionamos 1
  const ano = data.getFullYear();
  const agora = new Date();
  const hora = agora.getHours();
  const minutos = agora.getMinutes();
  const minutosFormatados = minutos.toString().padStart(2, '0');
  const [equipeAtual, setEquipeAtual] = useState('');

  useEffect(() => {
    atualizarDadosMovimento2()
  }, [])
  
  
  useEffect(() => {
    const equipesDePlantao = {
      '03/31/2024': 'Equipe C',
      '04/01/2024': 'Equipe D',
      '04/02/2024': 'Equipe A',
      '04/03/2024': 'Equipe B',
      '04/04/2024': 'Equipe C',
      '04/05/2024': 'Equipe D',
      '04/06/2024': 'Equipe A',
      '04/07/2024': 'Equipe B',
      '04/08/2024': 'Equipe C',
      '04/09/2024': 'Equipe D',
      '04/10/2024': 'Equipe A',
      '04/11/2024': 'Equipe B',
      '04/12/2024': 'Equipe C',
      '04/13/2024': 'Equipe D',
      '04/14/2024': 'Equipe A',
      '04/15/2024': 'Equipe B',
      '04/16/2024': 'Equipe C',
      '04/17/2024': 'Equipe D',
      '04/18/2024': 'Equipe A',
      '04/19/2024': 'Equipe B',
      '04/20/2024': 'Equipe C',
      '04/21/2024': 'Equipe D',
      '04/22/2024': 'Equipe A',
      '04/23/2024': 'Equipe B',
      '04/24/2024': 'Equipe C',
      '04/25/2024': 'Equipe D',
      '04/26/2024': 'Equipe A',
      '04/27/2024': 'Equipe B',
      '04/28/2024': 'Equipe C',
      '04/29/2024': 'Equipe D',
      '04/30/2024': 'Equipe A',
      '05/01/2024': 'Equipe B',
      '05/02/2024': 'Equipe C',
      '05/03/2024': 'Equipe D',
      '05/04/2024': 'Equipe A',
      '05/05/2024': 'Equipe B',
      '05/06/2024': 'Equipe C',
      '05/07/2024': 'Equipe D',
      '05/08/2024': 'Equipe A',
      '05/09/2024': 'Equipe B',
      '05/10/2024': 'Equipe C',
      '05/11/2024': 'Equipe D',
      '05/12/2024': 'Equipe A',
      '05/13/2024': 'Equipe B',
      '05/14/2024': 'Equipe C',
      '05/15/2024': 'Equipe D',
      '05/16/2024': 'Equipe A',
      '05/17/2024': 'Equipe B',
      '05/18/2024': 'Equipe C',
      '05/19/2024': 'Equipe D',
      '05/20/2024': 'Equipe A',
      '05/21/2024': 'Equipe B',
      '05/22/2024': 'Equipe C',
      '05/23/2024': 'Equipe D',
      '05/24/2024': 'Equipe A',
      '05/25/2024': 'Equipe B',
      '05/26/2024': 'Equipe C',
      '05/27/2024': 'Equipe D',
      '05/28/2024': 'Equipe A',
      '05/29/2024': 'Equipe B',
      '05/30/2024': 'Equipe C',
      '05/31/2024': 'Equipe D',
      '06/01/2024': 'Equipe A',
      '06/02/2024': 'Equipe B',
      '06/03/2024': 'Equipe C',
      '06/04/2024': 'Equipe D',
      '06/05/2024': 'Equipe A',
      '06/06/2024': 'Equipe B',
      '06/07/2024': 'Equipe C',
      '06/08/2024': 'Equipe D',
      '06/09/2024': 'Equipe A',
      '06/10/2024': 'Equipe B',
      '06/11/2024': 'Equipe C',
      '06/12/2024': 'Equipe D',
      '06/13/2024': 'Equipe A',
      '06/14/2024': 'Equipe B',
      '06/15/2024': 'Equipe C',
      '06/16/2024': 'Equipe D',
      '06/17/2024': 'Equipe A',
      '06/18/2024': 'Equipe B',
      '06/19/2024': 'Equipe C',
      '06/20/2024': 'Equipe D',
      '06/21/2024': 'Equipe A',
      '06/22/2024': 'Equipe B',
      '06/23/2024': 'Equipe C',
      '06/24/2024': 'Equipe D',
      '06/25/2024': 'Equipe A',
      '06/26/2024': 'Equipe B',
      '06/27/2024': 'Equipe C',
      '06/28/2024': 'Equipe D',
      '06/29/2024': 'Equipe A',
      '06/30/2024': 'Equipe B',
      '07/01/2024': 'Equipe C',
      '07/02/2024': 'Equipe D',
      '07/03/2024': 'Equipe A',
      '07/04/2024': 'Equipe B',
      '07/05/2024': 'Equipe C',
      '07/06/2024': 'Equipe D',
      '07/07/2024': 'Equipe A',
      '07/08/2024': 'Equipe B',
      '07/09/2024': 'Equipe C',
      '07/10/2024': 'Equipe D',
      '07/11/2024': 'Equipe A',
      '07/12/2024': 'Equipe B',
      '07/13/2024': 'Equipe C',
      '07/14/2024': 'Equipe D',
      '07/15/2024': 'Equipe A',
      '07/16/2024': 'Equipe B',
      '07/17/2024': 'Equipe C',
      '07/18/2024': 'Equipe D',
      '07/19/2024': 'Equipe A',
      '07/20/2024': 'Equipe B',
      '07/21/2024': 'Equipe C',
      '07/22/2024': 'Equipe D',
      '07/23/2024': 'Equipe A',
      '07/24/2024': 'Equipe B',
      '07/25/2024': 'Equipe C',
      '07/26/2024': 'Equipe D',
      '07/27/2024': 'Equipe A',
      '07/28/2024': 'Equipe B',
      '07/29/2024': 'Equipe C',
      '07/30/2024': 'Equipe D',
      '07/31/2024': 'Equipe A',
      '08/01/2024': 'Equipe B',
      '08/02/2024': 'Equipe C',
      '08/03/2024': 'Equipe D',
      '08/04/2024': 'Equipe A',
      '08/05/2024': 'Equipe B',
      '08/06/2024': 'Equipe C',
      '08/07/2024': 'Equipe D',
      '08/08/2024': 'Equipe A',
      '08/09/2024': 'Equipe B',
      '08/10/2024': 'Equipe C',
      '08/11/2024': 'Equipe D',
      '08/12/2024': 'Equipe A',
      '08/13/2024': 'Equipe B',
      '08/14/2024': 'Equipe C',
      '08/15/2024': 'Equipe D',
      '08/16/2024': 'Equipe A',
      '08/17/2024': 'Equipe B',
      '08/18/2024': 'Equipe C',
      '08/19/2024': 'Equipe D',
      '08/20/2024': 'Equipe A',
      '08/21/2024': 'Equipe B',
      '08/22/2024': 'Equipe C',
      '08/23/2024': 'Equipe D',
      '08/24/2024': 'Equipe A',
      '08/25/2024': 'Equipe B',
      '08/26/2024': 'Equipe C',
      '08/27/2024': 'Equipe D',
      '08/28/2024': 'Equipe A',
      '08/29/2024': 'Equipe B',
      '08/30/2024': 'Equipe C',
      '08/31/2024': 'Equipe D',
      '09/01/2024': 'Equipe A',
      '09/02/2024': 'Equipe B',
      '09/03/2024': 'Equipe C',
      '09/04/2024': 'Equipe D',
      '09/05/2024': 'Equipe A',
      '09/06/2024': 'Equipe B',
      '09/07/2024': 'Equipe C',









      // Adicione aqui as equipes para os próximos anos até 2026
    };

    const obterEquipe = () => {
      const agora = new Date();
      const horarioMinimo = new Date(agora);
      horarioMinimo.setHours(7, 0, 0, 0); // Define o horário mínimo para as 7 horas do dia atual

      // Se o horário atual for anterior às 7 horas, obter a equipe do dia anterior
      if (agora < horarioMinimo) {
        const diaAnterior = new Date(agora);
        diaAnterior.setDate(diaAnterior.getDate() - 1); // Obtém o dia anterior
        const diaAnteriorFormatado = diaAnterior.toLocaleDateString('en-US', { month: '2-digit', day: '2-digit', year: 'numeric' });

        if (equipesDePlantao.hasOwnProperty(diaAnteriorFormatado)) {
          setEquipeAtual(equipesDePlantao[diaAnteriorFormatado]);
          return; // Retorna para evitar executar a próxima verificação
        }
      }

      // Se não for antes das 7 horas ou se não houver equipe definida para o dia anterior, obter a equipe do dia atual
      const diaAtualFormatado = agora.toLocaleDateString('en-US', { month: '2-digit', day: '2-digit', year: 'numeric' });
      if (equipesDePlantao.hasOwnProperty(diaAtualFormatado)) {
        setEquipeAtual(equipesDePlantao[diaAtualFormatado]);
      } else {
        setEquipeAtual('');
      }
    };

    // Chama a função de obtenção da equipe inicialmente
    obterEquipe();

    // Define um intervalo para verificar a equipe a cada minuto
    const interval = setInterval(obterEquipe, 60000); // 60000 milissegundos = 1 minuto

    // Limpa o intervalo quando o componente é desmontado
    return () => clearInterval(interval);
  }, []);
  

  const exportToExcel = () => {
    // Função para extrair apenas os números da cela
    const extrairNumerosCela = (cela) => {
      if (!cela) return ''; // Se a cela for vazia, retorna uma string vazia
      return cela.replace(/\D/g, ''); // Remove todos os caracteres não numéricos
    };
  
    // Função para determinar a galeria com base na cela
    const getGaleria = (cela) => {
      // Verifica se a cela não está vazia
      if (cela && cela.trim() !== '') {
        const duasPrimeirasLetras = cela.substring(0, 2).toUpperCase();
        switch (duasPrimeirasLetras) {
          case 'DO':
            return 'P._DOMICILIAR';
          case 'A1':
            return 'ALFA';
          case 'A2':
            return 'ALFA';
          case 'B1':
            return 'BRAVO';
          case 'B2':
            return 'BRAVO';
          case 'C1':
            return 'CHARLIE';
          case 'C2':
            return 'CHARLIE';
          case 'D2':
            return 'DELTA';
          case 'TR':
            return 'TRIAGEM';
          case 'HO':
            return 'INT._HOSPITALAR';
          case 'PE':
            return 'PERNOITE';
          default:
            return ''; // Retorna uma string vazia se não corresponder a nenhum caso específico
        }
      } else {
        return ''; // Retorna uma string vazia se a cela estiver vazia
      }
    };
  
    // Filtra as galerias com cela não vazia e correspondente aos casos específicos
    const galeriasFiltradas = galerias.filter(item => {
      if (!item.cela || item.cela.trim() === '') return false; // Se a cela estiver vazia, não exporta
      const duasPrimeirasLetras = item.cela.substring(0, 2).toUpperCase();
      return ['A1','A2', 'B1','B2', 'C1','C2', 'D2', 'TR', 'HO', 'PE', 'DO'].includes(duasPrimeirasLetras); // Exporta apenas se as duas primeiras letras da cela corresponderem a essas letras
    });
  
    // Ordena os dados pelo campo "cela"
    const sortedData = galeriasFiltradas
      .sort((a, b) => (a.cela < b.cela ? -1 : 1))
      .map((item) => {
        // Extrai apenas os números da cela
        const celaNumerica = extrairNumerosCela(item.cela);
        // Determina a galeria com base na cela
        const galeria = getGaleria(item.cela);
        return { galeria, ...item };
      });
  
    // Define a ordem das colunas no Excel
    // Função para remover todas as letras de uma string
const removerLetras = (str) => {
  if (!str) return ''; // Retorna uma string vazia se o parâmetro for nulo ou vazio
  return str.replace(/[^\d]/g, ''); // Remove todos os caracteres que não são dígitos
};

// Define a ordem das colunas no Excel
const columnsOrder = ["infopen", "galeria", "cela", "nome", "origem", "selectDate" ,"entradaGaleria", "artigo", "selecao"];

// Mapeia os dados para garantir a ordem das colunas e remover letras da cela
const orderedData = sortedData.map(item => {
  const orderedItem = {};
  columnsOrder.forEach(column => {
    // Se a coluna for "cela", remove todas as letras antes de atribuir ao novo objeto
    if (column === "cela") {
      orderedItem[column] = removerLetras(item[column]);
    } else {
      orderedItem[column] = item[column];
    }
  });
  return orderedItem;
});

  
    // Converte os dados para uma planilha Excel
    const ws = XLSX.utils.json_to_sheet(orderedData);
  
    // Cria o workbook
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
  
    // Escreve o arquivo Excel
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
  
    // Cria um Blob para o arquivo Excel
    const dataBlob = new Blob([excelBuffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
  
    // Salva o arquivo Excel
    FileSaver.saveAs(dataBlob, `Contagem-${dia}-${mes}-${ano}.xlsx`);
  };
  
  

  const imprimirConteudo = () => {
    const conteudoParaImpressao = document.querySelector('.conteudo-para-impressao');
    if (conteudoParaImpressao) {
      window.print(conteudoParaImpressao);
    }
  };


  // criação das variáveis MAP GALERIA A
  const [pessoasSearchMapA101, setPessoasSearchMapA101] = useState('')
  const [pessoasSearchMapA102, setPessoasSearchMapA102] = useState('')
  const [pessoasSearchMapA103, setPessoasSearchMapA103] = useState('')
  const [pessoasSearchMapA104, setPessoasSearchMapA104] = useState('')
  const [pessoasSearchMapA105, setPessoasSearchMapA105] = useState('')
  const [pessoasSearchMapA106, setPessoasSearchMapA106] = useState('')
  const [pessoasSearchMapA107, setPessoasSearchMapA107] = useState('')
  const [pessoasSearchMapA108, setPessoasSearchMapA108] = useState('')
  const [pessoasSearchMapA109, setPessoasSearchMapA109] = useState('')
  const [pessoasSearchMapA110, setPessoasSearchMapA110] = useState('')
  const [pessoasSearchMapA111, setPessoasSearchMapA111] = useState('')
  const [pessoasSearchMapA112, setPessoasSearchMapA112] = useState('')
  const [pessoasSearchMapA113, setPessoasSearchMapA113] = useState('')
  const [pessoasSearchMapA114, setPessoasSearchMapA114] = useState('')
  const [pessoasSearchMapA115, setPessoasSearchMapA115] = useState('')
  const [pessoasSearchMapA116, setPessoasSearchMapA116] = useState('')
  const [pessoasSearchMapA117, setPessoasSearchMapA117] = useState('')
  const [pessoasSearchMapA118, setPessoasSearchMapA118] = useState('')
  const [pessoasSearchMapA119, setPessoasSearchMapA119] = useState('')
  const [pessoasSearchMapA120, setPessoasSearchMapA120] = useState('')
  const [pessoasSearchMapA121, setPessoasSearchMapA121] = useState('')
  const [pessoasSearchMapA122, setPessoasSearchMapA122] = useState('')
  const [pessoasSearchMapA201, setPessoasSearchMapA201] = useState('')
  const [pessoasSearchMapA202, setPessoasSearchMapA202] = useState('')
  const [pessoasSearchMapA203, setPessoasSearchMapA203] = useState('')
  const [pessoasSearchMapA204, setPessoasSearchMapA204] = useState('')
  const [pessoasSearchMapA205, setPessoasSearchMapA205] = useState('')
  const [pessoasSearchMapA206, setPessoasSearchMapA206] = useState('')
  const [pessoasSearchMapA207, setPessoasSearchMapA207] = useState('')
  const [pessoasSearchMapA208, setPessoasSearchMapA208] = useState('')
  const [pessoasSearchMapA209, setPessoasSearchMapA209] = useState('')
  const [pessoasSearchMapA210, setPessoasSearchMapA210] = useState('')
  const [pessoasSearchMapA211, setPessoasSearchMapA211] = useState('')
  const [pessoasSearchMapA212, setPessoasSearchMapA212] = useState('')
  const [pessoasSearchMapA213, setPessoasSearchMapA213] = useState('')
  const [pessoasSearchMapA214, setPessoasSearchMapA214] = useState('')
  const [pessoasSearchMapA215, setPessoasSearchMapA215] = useState('')
  const [pessoasSearchMapA216, setPessoasSearchMapA216] = useState('')
  const [pessoasSearchMapA217, setPessoasSearchMapA217] = useState('')
  const [pessoasSearchMapA218, setPessoasSearchMapA218] = useState('')
  const [pessoasSearchMapA219, setPessoasSearchMapA219] = useState('')
  const [pessoasSearchMapA220, setPessoasSearchMapA220] = useState('')
  const [pessoasSearchMapA221, setPessoasSearchMapA221] = useState('')
  const [pessoasSearchMapA222, setPessoasSearchMapA222] = useState('')

  // criação das variáveis MAP GALERIA B

  const [pessoasSearchMapB101, setPessoasSearchMapB101] = useState('')
  const [pessoasSearchMapB102, setPessoasSearchMapB102] = useState('')
  const [pessoasSearchMapB103, setPessoasSearchMapB103] = useState('')
  const [pessoasSearchMapB104, setPessoasSearchMapB104] = useState('')
  const [pessoasSearchMapB105, setPessoasSearchMapB105] = useState('')
  const [pessoasSearchMapB106, setPessoasSearchMapB106] = useState('')
  const [pessoasSearchMapB107, setPessoasSearchMapB107] = useState('')
  const [pessoasSearchMapB108, setPessoasSearchMapB108] = useState('')
  const [pessoasSearchMapB109, setPessoasSearchMapB109] = useState('')
  const [pessoasSearchMapB110, setPessoasSearchMapB110] = useState('')
  const [pessoasSearchMapB111, setPessoasSearchMapB111] = useState('')
  const [pessoasSearchMapB112, setPessoasSearchMapB112] = useState('')
  const [pessoasSearchMapB113, setPessoasSearchMapB113] = useState('')
  const [pessoasSearchMapB114, setPessoasSearchMapB114] = useState('')
  const [pessoasSearchMapB115, setPessoasSearchMapB115] = useState('')
  const [pessoasSearchMapB116, setPessoasSearchMapB116] = useState('')
  const [pessoasSearchMapB117, setPessoasSearchMapB117] = useState('')
  const [pessoasSearchMapB118, setPessoasSearchMapB118] = useState('')
  const [pessoasSearchMapB119, setPessoasSearchMapB119] = useState('')
  const [pessoasSearchMapB120, setPessoasSearchMapB120] = useState('')
  const [pessoasSearchMapB121, setPessoasSearchMapB121] = useState('')
  const [pessoasSearchMapB122, setPessoasSearchMapB122] = useState('')
  const [pessoasSearchMapB201, setPessoasSearchMapB201] = useState('')
  const [pessoasSearchMapB202, setPessoasSearchMapB202] = useState('')
  const [pessoasSearchMapB203, setPessoasSearchMapB203] = useState('')
  const [pessoasSearchMapB204, setPessoasSearchMapB204] = useState('')
  const [pessoasSearchMapB205, setPessoasSearchMapB205] = useState('')
  const [pessoasSearchMapB206, setPessoasSearchMapB206] = useState('')
  const [pessoasSearchMapB207, setPessoasSearchMapB207] = useState('')
  const [pessoasSearchMapB208, setPessoasSearchMapB208] = useState('')
  const [pessoasSearchMapB209, setPessoasSearchMapB209] = useState('')
  const [pessoasSearchMapB210, setPessoasSearchMapB210] = useState('')
  const [pessoasSearchMapB211, setPessoasSearchMapB211] = useState('')
  const [pessoasSearchMapB212, setPessoasSearchMapB212] = useState('')
  const [pessoasSearchMapB213, setPessoasSearchMapB213] = useState('')
  const [pessoasSearchMapB214, setPessoasSearchMapB214] = useState('')
  const [pessoasSearchMapB215, setPessoasSearchMapB215] = useState('')
  const [pessoasSearchMapB216, setPessoasSearchMapB216] = useState('')
  const [pessoasSearchMapB217, setPessoasSearchMapB217] = useState('')
  const [pessoasSearchMapB218, setPessoasSearchMapB218] = useState('')
  const [pessoasSearchMapB219, setPessoasSearchMapB219] = useState('')
  const [pessoasSearchMapB220, setPessoasSearchMapB220] = useState('')
  const [pessoasSearchMapB221, setPessoasSearchMapB221] = useState('')
  const [pessoasSearchMapB222, setPessoasSearchMapB222] = useState('')

  // criação das variáveis MAP GALERIA C

  const [pessoasSearchMapC101, setPessoasSearchMapC101] = useState('')
  const [pessoasSearchMapC102, setPessoasSearchMapC102] = useState('')
  const [pessoasSearchMapC103, setPessoasSearchMapC103] = useState('')
  const [pessoasSearchMapC104, setPessoasSearchMapC104] = useState('')
  const [pessoasSearchMapC105, setPessoasSearchMapC105] = useState('')
  const [pessoasSearchMapC106, setPessoasSearchMapC106] = useState('')
  const [pessoasSearchMapC107, setPessoasSearchMapC107] = useState('')
  const [pessoasSearchMapC108, setPessoasSearchMapC108] = useState('')
  const [pessoasSearchMapC109, setPessoasSearchMapC109] = useState('')
  const [pessoasSearchMapC110, setPessoasSearchMapC110] = useState('')
  const [pessoasSearchMapC111, setPessoasSearchMapC111] = useState('')
  const [pessoasSearchMapC112, setPessoasSearchMapC112] = useState('')
  const [pessoasSearchMapC113, setPessoasSearchMapC113] = useState('')
  const [pessoasSearchMapC114, setPessoasSearchMapC114] = useState('')
  const [pessoasSearchMapC115, setPessoasSearchMapC115] = useState('')
  const [pessoasSearchMapC116, setPessoasSearchMapC116] = useState('')
  const [pessoasSearchMapC117, setPessoasSearchMapC117] = useState('')
  const [pessoasSearchMapC118, setPessoasSearchMapC118] = useState('')
  const [pessoasSearchMapC119, setPessoasSearchMapC119] = useState('')
  const [pessoasSearchMapC120, setPessoasSearchMapC120] = useState('')
  const [pessoasSearchMapC121, setPessoasSearchMapC121] = useState('')
  const [pessoasSearchMapC122, setPessoasSearchMapC122] = useState('')
  const [pessoasSearchMapC201, setPessoasSearchMapC201] = useState('')
  const [pessoasSearchMapC202, setPessoasSearchMapC202] = useState('')
  const [pessoasSearchMapC203, setPessoasSearchMapC203] = useState('')
  const [pessoasSearchMapC204, setPessoasSearchMapC204] = useState('')
  const [pessoasSearchMapC205, setPessoasSearchMapC205] = useState('')
  const [pessoasSearchMapC206, setPessoasSearchMapC206] = useState('')
  const [pessoasSearchMapC207, setPessoasSearchMapC207] = useState('')
  const [pessoasSearchMapC208, setPessoasSearchMapC208] = useState('')
  const [pessoasSearchMapC209, setPessoasSearchMapC209] = useState('')
  const [pessoasSearchMapC210, setPessoasSearchMapC210] = useState('')
  const [pessoasSearchMapC211, setPessoasSearchMapC211] = useState('')
  const [pessoasSearchMapC212, setPessoasSearchMapC212] = useState('')
  const [pessoasSearchMapC213, setPessoasSearchMapC213] = useState('')
  const [pessoasSearchMapC214, setPessoasSearchMapC214] = useState('')
  const [pessoasSearchMapC215, setPessoasSearchMapC215] = useState('')
  const [pessoasSearchMapC216, setPessoasSearchMapC216] = useState('')
  const [pessoasSearchMapC217, setPessoasSearchMapC217] = useState('')
  const [pessoasSearchMapC218, setPessoasSearchMapC218] = useState('')
  const [pessoasSearchMapC219, setPessoasSearchMapC219] = useState('')
  const [pessoasSearchMapC220, setPessoasSearchMapC220] = useState('')
  const [pessoasSearchMapC221, setPessoasSearchMapC221] = useState('')
  const [pessoasSearchMapC222, setPessoasSearchMapC222] = useState('')

  // CRIAÇÃO DAS VARIAVEIS GALERIA D

  const [pessoasSearchMapD201, setPessoasSearchMapD201] = useState('')
  const [pessoasSearchMapD202, setPessoasSearchMapD202] = useState('')
  const [pessoasSearchMapD203, setPessoasSearchMapD203] = useState('')
  const [pessoasSearchMapD204, setPessoasSearchMapD204] = useState('')
  const [pessoasSearchMapD205, setPessoasSearchMapD205] = useState('')
  const [pessoasSearchMapD206, setPessoasSearchMapD206] = useState('')
  const [pessoasSearchMapD207, setPessoasSearchMapD207] = useState('')
  const [pessoasSearchMapD208, setPessoasSearchMapD208] = useState('')
  const [pessoasSearchMapD209, setPessoasSearchMapD209] = useState('')
  const [pessoasSearchMapD210, setPessoasSearchMapD210] = useState('')

  //variaveis da triagem

  const [pessoasSearchMapTR201, setPessoasSearchMapTR201] = useState('')
  const [pessoasSearchMapTR202, setPessoasSearchMapTR202] = useState('')





  //CRIAÇÃO DAS VARIÁVEIS DE PRESOS FORA DA UNIDADE

  const [pessoasDomiciliartotal, setPessoasDomiciliartotal] = useState('')
  const [pessoasHospitaltotal, setPessoasHospitaltotal] = useState('')
  const [pessoasPernoitetotal, setPessoasPernoitetotal] = useState('')



  // criação das variáveis de totalização do componente Cela
  const [pessoasSearchMapA101total, setPessoasSearchMapA101total] = useState('')
  const [pessoasSearchMapA102total, setPessoasSearchMapA102total] = useState('')
  const [pessoasSearchMapA103total, setPessoasSearchMapA103total] = useState('')
  const [pessoasSearchMapA104total, setPessoasSearchMapA104total] = useState('')
  const [pessoasSearchMapA105total, setPessoasSearchMapA105total] = useState('')
  const [pessoasSearchMapA106total, setPessoasSearchMapA106total] = useState('')
  const [pessoasSearchMapA107total, setPessoasSearchMapA107total] = useState('')
  const [pessoasSearchMapA108total, setPessoasSearchMapA108total] = useState('')
  const [pessoasSearchMapA109total, setPessoasSearchMapA109total] = useState('')
  const [pessoasSearchMapA110total, setPessoasSearchMapA110total] = useState('')
  const [pessoasSearchMapA111total, setPessoasSearchMapA111total] = useState('')
  const [pessoasSearchMapA112total, setPessoasSearchMapA112total] = useState('')
  const [pessoasSearchMapA113total, setPessoasSearchMapA113total] = useState('')
  const [pessoasSearchMapA114total, setPessoasSearchMapA114total] = useState('')
  const [pessoasSearchMapA115total, setPessoasSearchMapA115total] = useState('')
  const [pessoasSearchMapA116total, setPessoasSearchMapA116total] = useState('')
  const [pessoasSearchMapA117total, setPessoasSearchMapA117total] = useState('')
  const [pessoasSearchMapA118total, setPessoasSearchMapA118total] = useState('')
  const [pessoasSearchMapA119total, setPessoasSearchMapA119total] = useState('')
  const [pessoasSearchMapA120total, setPessoasSearchMapA120total] = useState('')
  const [pessoasSearchMapA121total, setPessoasSearchMapA121total] = useState('')
  const [pessoasSearchMapA122total, setPessoasSearchMapA122total] = useState('')
  const [pessoasSearchMapA201total, setPessoasSearchMapA201total] = useState('')
  const [pessoasSearchMapA202total, setPessoasSearchMapA202total] = useState('')
  const [pessoasSearchMapA203total, setPessoasSearchMapA203total] = useState('')
  const [pessoasSearchMapA204total, setPessoasSearchMapA204total] = useState('')
  const [pessoasSearchMapA205total, setPessoasSearchMapA205total] = useState('')
  const [pessoasSearchMapA206total, setPessoasSearchMapA206total] = useState('')
  const [pessoasSearchMapA207total, setPessoasSearchMapA207total] = useState('')
  const [pessoasSearchMapA208total, setPessoasSearchMapA208total] = useState('')
  const [pessoasSearchMapA209total, setPessoasSearchMapA209total] = useState('')
  const [pessoasSearchMapA210total, setPessoasSearchMapA210total] = useState('')
  const [pessoasSearchMapA211total, setPessoasSearchMapA211total] = useState('')
  const [pessoasSearchMapA212total, setPessoasSearchMapA212total] = useState('')
  const [pessoasSearchMapA213total, setPessoasSearchMapA213total] = useState('')
  const [pessoasSearchMapA214total, setPessoasSearchMapA214total] = useState('')
  const [pessoasSearchMapA215total, setPessoasSearchMapA215total] = useState('')
  const [pessoasSearchMapA216total, setPessoasSearchMapA216total] = useState('')
  const [pessoasSearchMapA217total, setPessoasSearchMapA217total] = useState('')
  const [pessoasSearchMapA218total, setPessoasSearchMapA218total] = useState('')
  const [pessoasSearchMapA219total, setPessoasSearchMapA219total] = useState('')
  const [pessoasSearchMapA220total, setPessoasSearchMapA220total] = useState('')
  const [pessoasSearchMapA221total, setPessoasSearchMapA221total] = useState('')
  const [pessoasSearchMapA222total, setPessoasSearchMapA222total] = useState('')

  // criação das variáveis MAP GALERIA B

  const [pessoasSearchMapB101total, setPessoasSearchMapB101total] = useState('')
  const [pessoasSearchMapB102total, setPessoasSearchMapB102total] = useState('')
  const [pessoasSearchMapB103total, setPessoasSearchMapB103total] = useState('')
  const [pessoasSearchMapB104total, setPessoasSearchMapB104total] = useState('')
  const [pessoasSearchMapB105total, setPessoasSearchMapB105total] = useState('')
  const [pessoasSearchMapB106total, setPessoasSearchMapB106total] = useState('')
  const [pessoasSearchMapB107total, setPessoasSearchMapB107total] = useState('')
  const [pessoasSearchMapB108total, setPessoasSearchMapB108total] = useState('')
  const [pessoasSearchMapB109total, setPessoasSearchMapB109total] = useState('')
  const [pessoasSearchMapB110total, setPessoasSearchMapB110total] = useState('')
  const [pessoasSearchMapB111total, setPessoasSearchMapB111total] = useState('')
  const [pessoasSearchMapB112total, setPessoasSearchMapB112total] = useState('')
  const [pessoasSearchMapB113total, setPessoasSearchMapB113total] = useState('')
  const [pessoasSearchMapB114total, setPessoasSearchMapB114total] = useState('')
  const [pessoasSearchMapB115total, setPessoasSearchMapB115total] = useState('')
  const [pessoasSearchMapB116total, setPessoasSearchMapB116total] = useState('')
  const [pessoasSearchMapB117total, setPessoasSearchMapB117total] = useState('')
  const [pessoasSearchMapB118total, setPessoasSearchMapB118total] = useState('')
  const [pessoasSearchMapB119total, setPessoasSearchMapB119total] = useState('')
  const [pessoasSearchMapB120total, setPessoasSearchMapB120total] = useState('')
  const [pessoasSearchMapB121total, setPessoasSearchMapB121total] = useState('')
  const [pessoasSearchMapB122total, setPessoasSearchMapB122total] = useState('')
  const [pessoasSearchMapB201total, setPessoasSearchMapB201total] = useState('')
  const [pessoasSearchMapB202total, setPessoasSearchMapB202total] = useState('')
  const [pessoasSearchMapB203total, setPessoasSearchMapB203total] = useState('')
  const [pessoasSearchMapB204total, setPessoasSearchMapB204total] = useState('')
  const [pessoasSearchMapB205total, setPessoasSearchMapB205total] = useState('')
  const [pessoasSearchMapB206total, setPessoasSearchMapB206total] = useState('')
  const [pessoasSearchMapB207total, setPessoasSearchMapB207total] = useState('')
  const [pessoasSearchMapB208total, setPessoasSearchMapB208total] = useState('')
  const [pessoasSearchMapB209total, setPessoasSearchMapB209total] = useState('')
  const [pessoasSearchMapB210total, setPessoasSearchMapB210total] = useState('')
  const [pessoasSearchMapB211total, setPessoasSearchMapB211total] = useState('')
  const [pessoasSearchMapB212total, setPessoasSearchMapB212total] = useState('')
  const [pessoasSearchMapB213total, setPessoasSearchMapB213total] = useState('')
  const [pessoasSearchMapB214total, setPessoasSearchMapB214total] = useState('')
  const [pessoasSearchMapB215total, setPessoasSearchMapB215total] = useState('')
  const [pessoasSearchMapB216total, setPessoasSearchMapB216total] = useState('')
  const [pessoasSearchMapB217total, setPessoasSearchMapB217total] = useState('')
  const [pessoasSearchMapB218total, setPessoasSearchMapB218total] = useState('')
  const [pessoasSearchMapB219total, setPessoasSearchMapB219total] = useState('')
  const [pessoasSearchMapB220total, setPessoasSearchMapB220total] = useState('')
  const [pessoasSearchMapB221total, setPessoasSearchMapB221total] = useState('')
  const [pessoasSearchMapB222total, setPessoasSearchMapB222total] = useState('')

  // criação das variáveis MAP GALERIA C

  const [pessoasSearchMapC101total, setPessoasSearchMapC101total] = useState('')
  const [pessoasSearchMapC102total, setPessoasSearchMapC102total] = useState('')
  const [pessoasSearchMapC103total, setPessoasSearchMapC103total] = useState('')
  const [pessoasSearchMapC104total, setPessoasSearchMapC104total] = useState('')
  const [pessoasSearchMapC105total, setPessoasSearchMapC105total] = useState('')
  const [pessoasSearchMapC106total, setPessoasSearchMapC106total] = useState('')
  const [pessoasSearchMapC107total, setPessoasSearchMapC107total] = useState('')
  const [pessoasSearchMapC108total, setPessoasSearchMapC108total] = useState('')
  const [pessoasSearchMapC109total, setPessoasSearchMapC109total] = useState('')
  const [pessoasSearchMapC110total, setPessoasSearchMapC110total] = useState('')
  const [pessoasSearchMapC111total, setPessoasSearchMapC111total] = useState('')
  const [pessoasSearchMapC112total, setPessoasSearchMapC112total] = useState('')
  const [pessoasSearchMapC113total, setPessoasSearchMapC113total] = useState('')
  const [pessoasSearchMapC114total, setPessoasSearchMapC114total] = useState('')
  const [pessoasSearchMapC115total, setPessoasSearchMapC115total] = useState('')
  const [pessoasSearchMapC116total, setPessoasSearchMapC116total] = useState('')
  const [pessoasSearchMapC117total, setPessoasSearchMapC117total] = useState('')
  const [pessoasSearchMapC118total, setPessoasSearchMapC118total] = useState('')
  const [pessoasSearchMapC119total, setPessoasSearchMapC119total] = useState('')
  const [pessoasSearchMapC120total, setPessoasSearchMapC120total] = useState('')
  const [pessoasSearchMapC121total, setPessoasSearchMapC121total] = useState('')
  const [pessoasSearchMapC122total, setPessoasSearchMapC122total] = useState('')
  const [pessoasSearchMapC201total, setPessoasSearchMapC201total] = useState('')
  const [pessoasSearchMapC202total, setPessoasSearchMapC202total] = useState('')
  const [pessoasSearchMapC203total, setPessoasSearchMapC203total] = useState('')
  const [pessoasSearchMapC204total, setPessoasSearchMapC204total] = useState('')
  const [pessoasSearchMapC205total, setPessoasSearchMapC205total] = useState('')
  const [pessoasSearchMapC206total, setPessoasSearchMapC206total] = useState('')
  const [pessoasSearchMapC207total, setPessoasSearchMapC207total] = useState('')
  const [pessoasSearchMapC208total, setPessoasSearchMapC208total] = useState('')
  const [pessoasSearchMapC209total, setPessoasSearchMapC209total] = useState('')
  const [pessoasSearchMapC210total, setPessoasSearchMapC210total] = useState('')
  const [pessoasSearchMapC211total, setPessoasSearchMapC211total] = useState('')
  const [pessoasSearchMapC212total, setPessoasSearchMapC212total] = useState('')
  const [pessoasSearchMapC213total, setPessoasSearchMapC213total] = useState('')
  const [pessoasSearchMapC214total, setPessoasSearchMapC214total] = useState('')
  const [pessoasSearchMapC215total, setPessoasSearchMapC215total] = useState('')
  const [pessoasSearchMapC216total, setPessoasSearchMapC216total] = useState('')
  const [pessoasSearchMapC217total, setPessoasSearchMapC217total] = useState('')
  const [pessoasSearchMapC218total, setPessoasSearchMapC218total] = useState('')
  const [pessoasSearchMapC219total, setPessoasSearchMapC219total] = useState('')
  const [pessoasSearchMapC220total, setPessoasSearchMapC220total] = useState('')
  const [pessoasSearchMapC221total, setPessoasSearchMapC221total] = useState('')
  const [pessoasSearchMapC222total, setPessoasSearchMapC222total] = useState('')

  // CRIAÇÃO DAS VARIAVEIS GALERIA D

  const [pessoasSearchMapD201total, setPessoasSearchMapD201total] = useState('')
  const [pessoasSearchMapD202total, setPessoasSearchMapD202total] = useState('')
  const [pessoasSearchMapD203total, setPessoasSearchMapD203total] = useState('')
  const [pessoasSearchMapD204total, setPessoasSearchMapD204total] = useState('')
  const [pessoasSearchMapD205total, setPessoasSearchMapD205total] = useState('')
  const [pessoasSearchMapD206total, setPessoasSearchMapD206total] = useState('')
  const [pessoasSearchMapD207total, setPessoasSearchMapD207total] = useState('')
  const [pessoasSearchMapD208total, setPessoasSearchMapD208total] = useState('')
  const [pessoasSearchMapD209total, setPessoasSearchMapD209total] = useState('')
  const [pessoasSearchMapD210total, setPessoasSearchMapD210total] = useState('')

  //variaveis da triagem

  const [pessoasSearchMapTR201total, setPessoasSearchMapTR201total] = useState('')
  const [pessoasSearchMapTR202total, setPessoasSearchMapTR202total] = useState('')


  // Criação das variáveis toalizadoras de valores
  const totalSomaA =
    pessoasSearchMapA101total +
    pessoasSearchMapA102total +
    pessoasSearchMapA103total +
    pessoasSearchMapA104total +
    pessoasSearchMapA105total +
    pessoasSearchMapA106total +
    pessoasSearchMapA107total +
    pessoasSearchMapA108total +
    pessoasSearchMapA109total +
    pessoasSearchMapA110total +
    pessoasSearchMapA111total +
    pessoasSearchMapA112total +
    pessoasSearchMapA113total +
    pessoasSearchMapA114total +
    pessoasSearchMapA115total +
    pessoasSearchMapA116total +
    pessoasSearchMapA117total +
    pessoasSearchMapA118total +
    pessoasSearchMapA119total +
    pessoasSearchMapA120total +
    pessoasSearchMapA121total +
    pessoasSearchMapA122total +
    pessoasSearchMapA201total +
    pessoasSearchMapA202total +
    pessoasSearchMapA203total +
    pessoasSearchMapA204total +
    pessoasSearchMapA205total +
    pessoasSearchMapA206total +
    pessoasSearchMapA207total +
    pessoasSearchMapA208total +
    pessoasSearchMapA209total +
    pessoasSearchMapA210total +
    pessoasSearchMapA211total +
    pessoasSearchMapA212total +
    pessoasSearchMapA213total +
    pessoasSearchMapA214total +
    pessoasSearchMapA215total +
    pessoasSearchMapA216total +
    pessoasSearchMapA217total +
    pessoasSearchMapA218total +
    pessoasSearchMapA219total +
    pessoasSearchMapA220total +
    pessoasSearchMapA221total +
    pessoasSearchMapA222total


  const totalQuadrante1A =

    pessoasSearchMapA101total +
    pessoasSearchMapA102total +
    pessoasSearchMapA103total +
    pessoasSearchMapA104total +
    pessoasSearchMapA105total +
    pessoasSearchMapA106total +
    pessoasSearchMapA107total +
    pessoasSearchMapA108total +
    pessoasSearchMapA109total +
    pessoasSearchMapA110total +
    pessoasSearchMapA111total +
    pessoasSearchMapA112total

  const totalQuadrante2A =
    pessoasSearchMapA113total +
    pessoasSearchMapA114total +
    pessoasSearchMapA115total +
    pessoasSearchMapA116total +
    pessoasSearchMapA117total +
    pessoasSearchMapA118total +
    pessoasSearchMapA119total +
    pessoasSearchMapA120total +
    pessoasSearchMapA121total +
    pessoasSearchMapA122total

  const totalQuadrante3A =
    pessoasSearchMapA201total +
    pessoasSearchMapA202total +
    pessoasSearchMapA203total +
    pessoasSearchMapA204total +
    pessoasSearchMapA205total +
    pessoasSearchMapA206total +
    pessoasSearchMapA207total +
    pessoasSearchMapA208total +
    pessoasSearchMapA209total +
    pessoasSearchMapA210total +
    pessoasSearchMapA211total +
    pessoasSearchMapA212total

  const totalQuadrante4A =
    pessoasSearchMapA213total +
    pessoasSearchMapA214total +
    pessoasSearchMapA215total +
    pessoasSearchMapA216total +
    pessoasSearchMapA217total +
    pessoasSearchMapA218total +
    pessoasSearchMapA219total +
    pessoasSearchMapA220total +
    pessoasSearchMapA221total +
    pessoasSearchMapA222total

  // GALERIA BRAVO 
  const totalSomaB =
    pessoasSearchMapB101total +
    pessoasSearchMapB102total +
    pessoasSearchMapB103total +
    pessoasSearchMapB104total +
    pessoasSearchMapB105total +
    pessoasSearchMapB106total +
    pessoasSearchMapB107total +
    pessoasSearchMapB108total +
    pessoasSearchMapB109total +
    pessoasSearchMapB110total +
    pessoasSearchMapB111total +
    pessoasSearchMapB112total +
    pessoasSearchMapB113total +
    pessoasSearchMapB114total +
    pessoasSearchMapB115total +
    pessoasSearchMapB116total +
    pessoasSearchMapB117total +
    pessoasSearchMapB118total +
    pessoasSearchMapB119total +
    pessoasSearchMapB120total +
    pessoasSearchMapB121total +
    pessoasSearchMapB122total +
    pessoasSearchMapB201total +
    pessoasSearchMapB202total +
    pessoasSearchMapB203total +
    pessoasSearchMapB204total +
    pessoasSearchMapB205total +
    pessoasSearchMapB206total +
    pessoasSearchMapB207total +
    pessoasSearchMapB208total +
    pessoasSearchMapB209total +
    pessoasSearchMapB210total +
    pessoasSearchMapB211total +
    pessoasSearchMapB212total +
    pessoasSearchMapB213total +
    pessoasSearchMapB214total +
    pessoasSearchMapB215total +
    pessoasSearchMapB216total +
    pessoasSearchMapB217total +
    pessoasSearchMapB218total +
    pessoasSearchMapB219total +
    pessoasSearchMapB220total +
    pessoasSearchMapB221total +
    pessoasSearchMapB222total

  // QUADRANTES BRAVO 

  const totalQuadrante1B =

    pessoasSearchMapB101total +
    pessoasSearchMapB102total +
    pessoasSearchMapB103total +
    pessoasSearchMapB104total +
    pessoasSearchMapB105total +
    pessoasSearchMapB106total +
    pessoasSearchMapB107total +
    pessoasSearchMapB108total +
    pessoasSearchMapB109total +
    pessoasSearchMapB110total +
    pessoasSearchMapB111total +
    pessoasSearchMapB112total

  const totalQuadrante2B =
    pessoasSearchMapB113total +
    pessoasSearchMapB114total +
    pessoasSearchMapB115total +
    pessoasSearchMapB116total +
    pessoasSearchMapB117total +
    pessoasSearchMapB118total +
    pessoasSearchMapB119total +
    pessoasSearchMapB120total +
    pessoasSearchMapB121total +
    pessoasSearchMapB122total

  const totalQuadrante3B =
    pessoasSearchMapB201total +
    pessoasSearchMapB202total +
    pessoasSearchMapB203total +
    pessoasSearchMapB204total +
    pessoasSearchMapB205total +
    pessoasSearchMapB206total +
    pessoasSearchMapB207total +
    pessoasSearchMapB208total +
    pessoasSearchMapB209total +
    pessoasSearchMapB210total +
    pessoasSearchMapB211total +
    pessoasSearchMapB212total

  const totalQuadrante4B =
    pessoasSearchMapB213total +
    pessoasSearchMapB214total +
    pessoasSearchMapB215total +
    pessoasSearchMapB216total +
    pessoasSearchMapB217total +
    pessoasSearchMapB218total +
    pessoasSearchMapB219total +
    pessoasSearchMapB220total +
    pessoasSearchMapB221total +
    pessoasSearchMapB222total

  // GALERIA CHARLIE


  const totalSomaC =
    pessoasSearchMapC101total +
    pessoasSearchMapC102total +
    pessoasSearchMapC103total +
    pessoasSearchMapC104total +
    pessoasSearchMapC105total +
    pessoasSearchMapC106total +
    pessoasSearchMapC107total +
    pessoasSearchMapC108total +
    pessoasSearchMapC109total +
    pessoasSearchMapC110total +
    pessoasSearchMapC111total +
    pessoasSearchMapC112total +
    pessoasSearchMapC113total +
    pessoasSearchMapC114total +
    pessoasSearchMapC115total +
    pessoasSearchMapC116total +
    pessoasSearchMapC117total +
    pessoasSearchMapC118total +
    pessoasSearchMapC119total +
    pessoasSearchMapC120total +
    pessoasSearchMapC121total +
    pessoasSearchMapC122total +
    pessoasSearchMapC201total +
    pessoasSearchMapC202total +
    pessoasSearchMapC203total +
    pessoasSearchMapC204total +
    pessoasSearchMapC205total +
    pessoasSearchMapC206total +
    pessoasSearchMapC207total +
    pessoasSearchMapC208total +
    pessoasSearchMapC209total +
    pessoasSearchMapC210total +
    pessoasSearchMapC211total +
    pessoasSearchMapC212total +
    pessoasSearchMapC213total +
    pessoasSearchMapC214total +
    pessoasSearchMapC215total +
    pessoasSearchMapC216total +
    pessoasSearchMapC217total +
    pessoasSearchMapC218total +
    pessoasSearchMapC219total +
    pessoasSearchMapC220total +
    pessoasSearchMapC221total +
    pessoasSearchMapC222total

  // QUADRANTES CHARLIE 

  const totalQuadrante1C =

    pessoasSearchMapC101total +
    pessoasSearchMapC102total +
    pessoasSearchMapC103total +
    pessoasSearchMapC104total +
    pessoasSearchMapC105total +
    pessoasSearchMapC106total +
    pessoasSearchMapC107total +
    pessoasSearchMapC108total +
    pessoasSearchMapC109total +
    pessoasSearchMapC110total +
    pessoasSearchMapC111total +
    pessoasSearchMapC112total

  const totalQuadrante2C =
    pessoasSearchMapC113total +
    pessoasSearchMapC114total +
    pessoasSearchMapC115total +
    pessoasSearchMapC116total +
    pessoasSearchMapC117total +
    pessoasSearchMapC118total +
    pessoasSearchMapC119total +
    pessoasSearchMapC120total +
    pessoasSearchMapC121total +
    pessoasSearchMapC122total

  const totalQuadrante3C =
    pessoasSearchMapC201total +
    pessoasSearchMapC202total +
    pessoasSearchMapC203total +
    pessoasSearchMapC204total +
    pessoasSearchMapC205total +
    pessoasSearchMapC206total +
    pessoasSearchMapC207total +
    pessoasSearchMapC208total +
    pessoasSearchMapC209total +
    pessoasSearchMapC210total +
    pessoasSearchMapC211total +
    pessoasSearchMapC212total

  const totalQuadrante4C =
    pessoasSearchMapC213total +
    pessoasSearchMapC214total +
    pessoasSearchMapC215total +
    pessoasSearchMapC216total +
    pessoasSearchMapC217total +
    pessoasSearchMapC218total +
    pessoasSearchMapC219total +
    pessoasSearchMapC220total +
    pessoasSearchMapC221total +
    pessoasSearchMapC222total



  const totalSomaD =
    pessoasSearchMapD201total +
    pessoasSearchMapD202total +
    pessoasSearchMapD203total +
    pessoasSearchMapD204total +
    pessoasSearchMapD205total +
    pessoasSearchMapD206total +
    pessoasSearchMapD207total +
    pessoasSearchMapD208total +
    pessoasSearchMapD209total +
    pessoasSearchMapD210total

  const totalQuadrante1D =
    pessoasSearchMapD201total +
    pessoasSearchMapD202total +
    pessoasSearchMapD203total +
    pessoasSearchMapD204total +
    pessoasSearchMapD205total +
    pessoasSearchMapD206total


  const totalQuadrante2D =

    pessoasSearchMapD207total +
    pessoasSearchMapD208total +
    pessoasSearchMapD209total +
    pessoasSearchMapD210total


  const totalSomaTR =
    pessoasSearchMapTR201total +
    pessoasSearchMapTR202total



  const totalUp =
    totalSomaA +
    totalSomaB +
    totalSomaC +
    totalSomaD +
    totalSomaTR

  const totalGeral = totalUp + pessoasDomiciliartotal + pessoasHospitaltotal + pessoasPernoitetotal







  const paginaCapa = () => {

    setAtivaBotao(6)
    setPagina(6)


  }
  const paginaGaleriaA = () => {
    setAtivaBotao(1)
    setPagina(1)

  }
  const paginaGaleriaB = () => {
    setAtivaBotao(2)
    setPagina(2)

  }
  const paginaGaleriaC = () => {

    setAtivaBotao(3)
    setPagina(3)

  }
  const paginaGaleriaD = () => {

    setAtivaBotao(4)
    setPagina(4)

  }
  const paginaGeral = () => {

    setAtivaBotao(5)
    setPagina(5)

  }

  

  // A101
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaA101 = "A101";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapA101(filteredResults);
      setPessoasSearchMapA101total(filteredResults.length)

    }
    //ABAIXO O CAMPO DA FUNÇÃO QUE IRA EFETUAR A PESQUISA
    performSearch((celaA101).toUpperCase())
  }, [galerias])

  // A102
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaA102 = "A102";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapA102(filteredResults);
      setPessoasSearchMapA102total(filteredResults.length)

    }
    //ABAIXO O CAMPO DA FUNÇÃO QUE IRA EFETUAR A PESQUISA
    performSearch((celaA102).toUpperCase())
  }, [galerias])

  // A103
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaA103 = "A103";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapA103(filteredResults);
      setPessoasSearchMapA103total(filteredResults.length)

    }
    //ABAIXO O CAMPO DA FUNÇÃO QUE IRA EFETUAR A PESQUISA
    performSearch((celaA103).toUpperCase())
  }, [galerias])

  // A104
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaA104 = "A104";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapA104(filteredResults);
      setPessoasSearchMapA104total(filteredResults.length)

    }
    //ABAIXO O CAMPO DA FUNÇÃO QUE IRA EFETUAR A PESQUISA
    performSearch((celaA104).toUpperCase())
  }, [galerias])
  // A105
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaA105 = "A105";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapA105(filteredResults);
      setPessoasSearchMapA105total(filteredResults.length)

    }
    //ABAIXO O CAMPO DA FUNÇÃO QUE IRA EFETUAR A PESQUISA
    performSearch((celaA105).toUpperCase())
  }, [galerias])

  // A106
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaA106 = "A106";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapA106(filteredResults);
      setPessoasSearchMapA106total(filteredResults.length)

    }
    //ABAIXO O CAMPO DA FUNÇÃO QUE IRA EFETUAR A PESQUISA
    performSearch((celaA106).toUpperCase())
  }, [galerias])

  // A107
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaA107 = "A107";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapA107(filteredResults);
      setPessoasSearchMapA107total(filteredResults.length)

    }
    //ABAIXO O CAMPO DA FUNÇÃO QUE IRA EFETUAR A PESQUISA
    performSearch((celaA107).toUpperCase())
  }, [galerias])


  // A108
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaA108 = "A108";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapA108(filteredResults);
      setPessoasSearchMapA108total(filteredResults.length)

    }
    //ABAIXO O CAMPO DA FUNÇÃO QUE IRA EFETUAR A PESQUISA
    performSearch((celaA108).toUpperCase())
  }, [galerias])
  // A109
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaA109 = "A109";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapA109(filteredResults);
      setPessoasSearchMapA109total(filteredResults.length)

    }
    //ABAIXO O CAMPO DA FUNÇÃO QUE IRA EFETUAR A PESQUISA
    performSearch((celaA109).toUpperCase())
  }, [galerias])

  // A110
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaA110 = "A110";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapA110(filteredResults);
      setPessoasSearchMapA110total(filteredResults.length)

    }
    //ABAIXO O CAMPO DA FUNÇÃO QUE IRA EFETUAR A PESQUISA
    performSearch((celaA110).toUpperCase())
  }, [galerias])
  // A111
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaA111 = "A111";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapA111(filteredResults);
      setPessoasSearchMapA111total(filteredResults.length)

    }
    //ABAIXO O CAMPO DA FUNÇÃO QUE IRA EFETUAR A PESQUISA
    performSearch((celaA111).toUpperCase())
  }, [galerias])

  // A112
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaA112 = "A112";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapA112(filteredResults);
      setPessoasSearchMapA112total(filteredResults.length)

    }
    //ABAIXO O CAMPO DA FUNÇÃO QUE IRA EFETUAR A PESQUISA
    performSearch((celaA112).toUpperCase())
  }, [galerias])


  // A113
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaA113 = "A113";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapA113(filteredResults);
      setPessoasSearchMapA113total(filteredResults.length)

    }
    //ABAIXO O CAMPO DA FUNÇÃO QUE IRA EFETUAR A PESQUISA
    performSearch((celaA113).toUpperCase())
  }, [galerias])

  // A114
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaA114 = "A114";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapA114(filteredResults);
      setPessoasSearchMapA114total(filteredResults.length)

    }
    //ABAIXO O CAMPO DA FUNÇÃO QUE IRA EFETUAR A PESQUISA
    performSearch((celaA114).toUpperCase())
  }, [galerias])

  // A115
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaA115 = "A115";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapA115(filteredResults);
      setPessoasSearchMapA115total(filteredResults.length)

    }
    //ABAIXO O CAMPO DA FUNÇÃO QUE IRA EFETUAR A PESQUISA
    performSearch((celaA115).toUpperCase())
  }, [galerias])

  // A116
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaA116 = "A116";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapA116(filteredResults);
      setPessoasSearchMapA116total(filteredResults.length)

    }
    //ABAIXO O CAMPO DA FUNÇÃO QUE IRA EFETUAR A PESQUISA
    performSearch((celaA116).toUpperCase())
  }, [galerias])


  // A117
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaA117 = "A117";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapA117(filteredResults);
      setPessoasSearchMapA117total(filteredResults.length)

    }
    //ABAIXO O CAMPO DA FUNÇÃO QUE IRA EFETUAR A PESQUISA
    performSearch((celaA117).toUpperCase())
  }, [galerias])

  // A118
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaA118 = "A118";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapA118(filteredResults);
      setPessoasSearchMapA118total(filteredResults.length)

    }
    //ABAIXO O CAMPO DA FUNÇÃO QUE IRA EFETUAR A PESQUISA
    performSearch((celaA118).toUpperCase())
  }, [galerias])

  // A119
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaA119 = "A119";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapA119(filteredResults);
      setPessoasSearchMapA119total(filteredResults.length)

    }
    //ABAIXO O CAMPO DA FUNÇÃO QUE IRA EFETUAR A PESQUISA
    performSearch((celaA119).toUpperCase())
  }, [galerias])

  // A120
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaA120 = "A120";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapA120(filteredResults);
      setPessoasSearchMapA120total(filteredResults.length)

    }
    //ABAIXO O CAMPO DA FUNÇÃO QUE IRA EFETUAR A PESQUISA
    performSearch((celaA120).toUpperCase())
  }, [galerias])

  // A121
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaA121 = "A121";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapA121(filteredResults);
      setPessoasSearchMapA121total(filteredResults.length)

    }
    //ABAIXO O CAMPO DA FUNÇÃO QUE IRA EFETUAR A PESQUISA
    performSearch((celaA121).toUpperCase())
  }, [galerias])

  // A122
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaA122 = "A122";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapA122(filteredResults);
      setPessoasSearchMapA122total(filteredResults.length)

    }
    //ABAIXO O CAMPO DA FUNÇÃO QUE IRA EFETUAR A PESQUISA
    performSearch((celaA122).toUpperCase())
  }, [galerias])
  ///////////////////////////////////////////////////////////////////////


  // A201
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaA201 = "A201";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapA201(filteredResults);
      setPessoasSearchMapA201total(filteredResults.length)

    }
    //ABAIXO O CAMPO DA FUNÇÃO QUE IRA EFETUAR A PESQUISA
    performSearch((celaA201).toUpperCase())
  }, [galerias])

  // A202
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaA202 = "A202";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapA202(filteredResults);
      setPessoasSearchMapA202total(filteredResults.length)

    }
    //ABAIXO O CAMPO DA FUNÇÃO QUE IRA EFETUAR A PESQUISA
    performSearch((celaA202).toUpperCase())
  }, [galerias])

  // A203
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaA203 = "A203";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapA203(filteredResults);
      setPessoasSearchMapA203total(filteredResults.length)

    }
    //ABAIXO O CAMPO DA FUNÇÃO QUE IRA EFETUAR A PESQUISA
    performSearch((celaA203).toUpperCase())
  }, [galerias])

  // A204
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaA204 = "A204";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapA204(filteredResults);
      setPessoasSearchMapA204total(filteredResults.length)

    }
    //ABAIXO O CAMPO DA FUNÇÃO QUE IRA EFETUAR A PESQUISA
    performSearch((celaA204).toUpperCase())
  }, [galerias])
  // A205
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaA205 = "A205";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapA205(filteredResults);
      setPessoasSearchMapA205total(filteredResults.length)

    }
    //ABAIXO O CAMPO DA FUNÇÃO QUE IRA EFETUAR A PESQUISA
    performSearch((celaA205).toUpperCase())
  }, [galerias])

  // A206
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaA206 = "A206";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapA206(filteredResults);
      setPessoasSearchMapA206total(filteredResults.length)

    }
    //ABAIXO O CAMPO DA FUNÇÃO QUE IRA EFETUAR A PESQUISA
    performSearch((celaA206).toUpperCase())
  }, [galerias])

  // A207
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaA207 = "A207";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapA207(filteredResults);
      setPessoasSearchMapA207total(filteredResults.length)

    }
    //ABAIXO O CAMPO DA FUNÇÃO QUE IRA EFETUAR A PESQUISA
    performSearch((celaA207).toUpperCase())
  }, [galerias])


  // A208
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaA208 = "A208";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapA208(filteredResults);
      setPessoasSearchMapA208total(filteredResults.length)

    }
    //ABAIXO O CAMPO DA FUNÇÃO QUE IRA EFETUAR A PESQUISA
    performSearch((celaA208).toUpperCase())
  }, [galerias])
  // A209
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaA209 = "A209";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapA209(filteredResults);
      setPessoasSearchMapA209total(filteredResults.length)

    }
    //ABAIXO O CAMPO DA FUNÇÃO QUE IRA EFETUAR A PESQUISA
    performSearch((celaA209).toUpperCase())
  }, [galerias])

  // A210
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaA210 = "A210";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapA210(filteredResults);
      setPessoasSearchMapA210total(filteredResults.length)

    }
    //ABAIXO O CAMPO DA FUNÇÃO QUE IRA EFETUAR A PESQUISA
    performSearch((celaA210).toUpperCase())
  }, [galerias])
  // A211
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaA211 = "A211";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapA211(filteredResults);
      setPessoasSearchMapA211total(filteredResults.length)

    }
    //ABAIXO O CAMPO DA FUNÇÃO QUE IRA EFETUAR A PESQUISA
    performSearch((celaA211).toUpperCase())
  }, [galerias])

  // A212
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaA212 = "A212";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapA212(filteredResults);
      setPessoasSearchMapA212total(filteredResults.length)

    }
    //ABAIXO O CAMPO DA FUNÇÃO QUE IRA EFETUAR A PESQUISA
    performSearch((celaA212).toUpperCase())
  }, [galerias])

  // A213
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaA213 = "A213";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapA213(filteredResults);
      setPessoasSearchMapA213total(filteredResults.length)

    }
    //ABAIXO O CAMPO DA FUNÇÃO QUE IRA EFETUAR A PESQUISA
    performSearch((celaA213).toUpperCase())
  }, [galerias])

  // A214
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaA214 = "A214";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapA214(filteredResults);
      setPessoasSearchMapA214total(filteredResults.length)

    }
    //ABAIXO O CAMPO DA FUNÇÃO QUE IRA EFETUAR A PESQUISA
    performSearch((celaA214).toUpperCase())
  }, [galerias])

  // A215
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaA215 = "A215";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapA215(filteredResults);
      setPessoasSearchMapA215total(filteredResults.length)

    }
    //ABAIXO O CAMPO DA FUNÇÃO QUE IRA EFETUAR A PESQUISA
    performSearch((celaA215).toUpperCase())
  }, [galerias])

  // A216
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaA216 = "A216";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapA216(filteredResults);
      setPessoasSearchMapA216total(filteredResults.length)

    }
    //ABAIXO O CAMPO DA FUNÇÃO QUE IRA EFETUAR A PESQUISA
    performSearch((celaA216).toUpperCase())
  }, [galerias])


  // A217
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaA217 = "A217";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapA217(filteredResults);
      setPessoasSearchMapA217total(filteredResults.length)

    }
    //ABAIXO O CAMPO DA FUNÇÃO QUE IRA EFETUAR A PESQUISA
    performSearch((celaA217).toUpperCase())
  }, [galerias])

  // A218
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaA218 = "A218";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapA218(filteredResults);
      setPessoasSearchMapA218total(filteredResults.length)

    }
    //ABAIXO O CAMPO DA FUNÇÃO QUE IRA EFETUAR A PESQUISA
    performSearch((celaA218).toUpperCase())
  }, [galerias])

  // A219
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaA219 = "A219";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapA219(filteredResults);
      setPessoasSearchMapA219total(filteredResults.length)

    }
    //ABAIXO O CAMPO DA FUNÇÃO QUE IRA EFETUAR A PESQUISA
    performSearch((celaA219).toUpperCase())
  }, [galerias])

  // A220
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaA220 = "A220";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapA220(filteredResults);
      setPessoasSearchMapA220total(filteredResults.length)

    }
    //ABAIXO O CAMPO DA FUNÇÃO QUE IRA EFETUAR A PESQUISA
    performSearch((celaA220).toUpperCase())
  }, [galerias])

  // A221
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaA221 = "A221";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapA221(filteredResults);
      setPessoasSearchMapA221total(filteredResults.length)

    }
    //ABAIXO O CAMPO DA FUNÇÃO QUE IRA EFETUAR A PESQUISA
    performSearch((celaA221).toUpperCase())
  }, [galerias])

  // A222
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaA222 = "A222";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapA222(filteredResults);
      setPessoasSearchMapA222total(filteredResults.length)

    }
    //ABAIXO O CAMPO DA FUNÇÃO QUE IRA EFETUAR A PESQUISA
    performSearch((celaA222).toUpperCase())
  }, [galerias])

  // B101
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaB101 = "B101";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapB101(filteredResults);
      setPessoasSearchMapB101total(filteredResults.length)

    }
    //BBBIXO O CBMPO DB FUNÇÃO QUE IRB EFETUBR B PESQUISB
    performSearch((celaB101).toUpperCase())
  }, [galerias])

  // B102
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaB102 = "B102";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapB102(filteredResults);
      setPessoasSearchMapB102total(filteredResults.length)

    }
    //BBBIXO O CBMPO DB FUNÇÃO QUE IRB EFETUBR B PESQUISB
    performSearch((celaB102).toUpperCase())
  }, [galerias])

  // B103
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaB103 = "B103";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapB103(filteredResults);
      setPessoasSearchMapB103total(filteredResults.length)

    }
    //BBBIXO O CBMPO DB FUNÇÃO QUE IRB EFETUBR B PESQUISB
    performSearch((celaB103).toUpperCase())
  }, [galerias])

  // B104
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaB104 = "B104";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapB104(filteredResults);
      setPessoasSearchMapB104total(filteredResults.length)

    }
    //BBBIXO O CBMPO DB FUNÇÃO QUE IRB EFETUBR B PESQUISB
    performSearch((celaB104).toUpperCase())
  }, [galerias])
  // B105
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaB105 = "B105";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapB105(filteredResults);
      setPessoasSearchMapB105total(filteredResults.length)

    }
    //BBBIXO O CBMPO DB FUNÇÃO QUE IRB EFETUBR B PESQUISB
    performSearch((celaB105).toUpperCase())
  }, [galerias])

  // B106
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaB106 = "B106";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapB106(filteredResults);
      setPessoasSearchMapB106total(filteredResults.length)

    }
    //BBBIXO O CBMPO DB FUNÇÃO QUE IRB EFETUBR B PESQUISB
    performSearch((celaB106).toUpperCase())
  }, [galerias])

  // B107
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaB107 = "B107";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapB107(filteredResults);
      setPessoasSearchMapB107total(filteredResults.length)

    }
    //BBBIXO O CBMPO DB FUNÇÃO QUE IRB EFETUBR B PESQUISB
    performSearch((celaB107).toUpperCase())
  }, [galerias])


  // B108
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaB108 = "B108";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapB108(filteredResults);
      setPessoasSearchMapB108total(filteredResults.length)

    }
    //BBBIXO O CBMPO DB FUNÇÃO QUE IRB EFETUBR B PESQUISB
    performSearch((celaB108).toUpperCase())
  }, [galerias])
  // B109
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaB109 = "B109";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapB109(filteredResults);
      setPessoasSearchMapB109total(filteredResults.length)

    }
    //BBBIXO O CBMPO DB FUNÇÃO QUE IRB EFETUBR B PESQUISB
    performSearch((celaB109).toUpperCase())
  }, [galerias])

  // B110
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaB110 = "B110";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapB110(filteredResults);
      setPessoasSearchMapB110total(filteredResults.length)

    }
    //BBBIXO O CBMPO DB FUNÇÃO QUE IRB EFETUBR B PESQUISB
    performSearch((celaB110).toUpperCase())
  }, [galerias])
  // B111
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaB111 = "B111";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapB111(filteredResults);
      setPessoasSearchMapB111total(filteredResults.length)

    }
    //BBBIXO O CBMPO DB FUNÇÃO QUE IRB EFETUBR B PESQUISB
    performSearch((celaB111).toUpperCase())
  }, [galerias])

  // B112
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaB112 = "B112";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapB112(filteredResults);
      setPessoasSearchMapB112total(filteredResults.length)

    }
    //BBBIXO O CBMPO DB FUNÇÃO QUE IRB EFETUBR B PESQUISB
    performSearch((celaB112).toUpperCase())
  }, [galerias])


  // B113
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaB113 = "B113";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapB113(filteredResults);
      setPessoasSearchMapB113total(filteredResults.length)

    }
    //BBBIXO O CBMPO DB FUNÇÃO QUE IRB EFETUBR B PESQUISB
    performSearch((celaB113).toUpperCase())
  }, [galerias])

  // B114
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaB114 = "B114";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapB114(filteredResults);
      setPessoasSearchMapB114total(filteredResults.length)

    }
    //BBBIXO O CBMPO DB FUNÇÃO QUE IRB EFETUBR B PESQUISB
    performSearch((celaB114).toUpperCase())
  }, [galerias])

  // B115
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaB115 = "B115";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapB115(filteredResults);
      setPessoasSearchMapB115total(filteredResults.length)

    }
    //BBBIXO O CBMPO DB FUNÇÃO QUE IRB EFETUBR B PESQUISB
    performSearch((celaB115).toUpperCase())
  }, [galerias])

  // B116
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaB116 = "B116";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapB116(filteredResults);
      setPessoasSearchMapB116total(filteredResults.length)

    }
    //BBBIXO O CBMPO DB FUNÇÃO QUE IRB EFETUBR B PESQUISB
    performSearch((celaB116).toUpperCase())
  }, [galerias])


  // B117
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaB117 = "B117";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapB117(filteredResults);
      setPessoasSearchMapB117total(filteredResults.length)

    }
    //BBBIXO O CBMPO DB FUNÇÃO QUE IRB EFETUBR B PESQUISB
    performSearch((celaB117).toUpperCase())
  }, [galerias])

  // B118
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaB118 = "B118";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapB118(filteredResults);
      setPessoasSearchMapB118total(filteredResults.length)

    }
    //BBBIXO O CBMPO DB FUNÇÃO QUE IRB EFETUBR B PESQUISB
    performSearch((celaB118).toUpperCase())
  }, [galerias])

  // B119
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaB119 = "B119";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapB119(filteredResults);
      setPessoasSearchMapB119total(filteredResults.length)

    }
    //BBBIXO O CBMPO DB FUNÇÃO QUE IRB EFETUBR B PESQUISB
    performSearch((celaB119).toUpperCase())
  }, [galerias])

  // B120
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaB120 = "B120";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapB120(filteredResults);
      setPessoasSearchMapB120total(filteredResults.length)

    }
    //BBBIXO O CBMPO DB FUNÇÃO QUE IRB EFETUBR B PESQUISB
    performSearch((celaB120).toUpperCase())
  }, [galerias])

  // B121
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaB121 = "B121";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapB121(filteredResults);
      setPessoasSearchMapB121total(filteredResults.length)

    }
    //BBBIXO O CBMPO DB FUNÇÃO QUE IRB EFETUBR B PESQUISB
    performSearch((celaB121).toUpperCase())
  }, [galerias])

  // B122
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaB122 = "B122";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapB122(filteredResults);
      setPessoasSearchMapB122total(filteredResults.length)

    }
    //BBBIXO O CBMPO DB FUNÇÃO QUE IRB EFETUBR B PESQUISB
    performSearch((celaB122).toUpperCase())
  }, [galerias])
  ///////////////////////////////////////////////////////////////////////


  // B201
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaB201 = "B201";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapB201(filteredResults);
      setPessoasSearchMapB201total(filteredResults.length)

    }
    //BBBIXO O CBMPO DB FUNÇÃO QUE IRB EFETUBR B PESQUISB
    performSearch((celaB201).toUpperCase())
  }, [galerias])

  // B202
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaB202 = "B202";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapB202(filteredResults);
      setPessoasSearchMapB202total(filteredResults.length)

    }
    //BBBIXO O CBMPO DB FUNÇÃO QUE IRB EFETUBR B PESQUISB
    performSearch((celaB202).toUpperCase())
  }, [galerias])

  // B203
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaB203 = "B203";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapB203(filteredResults);
      setPessoasSearchMapB203total(filteredResults.length)

    }
    //BBBIXO O CBMPO DB FUNÇÃO QUE IRB EFETUBR B PESQUISB
    performSearch((celaB203).toUpperCase())
  }, [galerias])

  // B204
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaB204 = "B204";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapB204(filteredResults);
      setPessoasSearchMapB204total(filteredResults.length)

    }
    //BBBIXO O CBMPO DB FUNÇÃO QUE IRB EFETUBR B PESQUISB
    performSearch((celaB204).toUpperCase())
  }, [galerias])
  // B205
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaB205 = "B205";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapB205(filteredResults);
      setPessoasSearchMapB205total(filteredResults.length)

    }
    //BBBIXO O CBMPO DB FUNÇÃO QUE IRB EFETUBR B PESQUISB
    performSearch((celaB205).toUpperCase())
  }, [galerias])

  // B206
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaB206 = "B206";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapB206(filteredResults);
      setPessoasSearchMapB206total(filteredResults.length)

    }
    //BBBIXO O CBMPO DB FUNÇÃO QUE IRB EFETUBR B PESQUISB
    performSearch((celaB206).toUpperCase())
  }, [galerias])

  // B207
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaB207 = "B207";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapB207(filteredResults);
      setPessoasSearchMapB207total(filteredResults.length)

    }
    //BBBIXO O CBMPO DB FUNÇÃO QUE IRB EFETUBR B PESQUISB
    performSearch((celaB207).toUpperCase())
  }, [galerias])


  // B208
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaB208 = "B208";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapB208(filteredResults);
      setPessoasSearchMapB208total(filteredResults.length)

    }
    //BBBIXO O CBMPO DB FUNÇÃO QUE IRB EFETUBR B PESQUISB
    performSearch((celaB208).toUpperCase())
  }, [galerias])
  // B209
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaB209 = "B209";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapB209(filteredResults);
      setPessoasSearchMapB209total(filteredResults.length)

    }
    //BBBIXO O CBMPO DB FUNÇÃO QUE IRB EFETUBR B PESQUISB
    performSearch((celaB209).toUpperCase())
  }, [galerias])

  // B210
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaB210 = "B210";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapB210(filteredResults);
      setPessoasSearchMapB210total(filteredResults.length)

    }
    //BBBIXO O CBMPO DB FUNÇÃO QUE IRB EFETUBR B PESQUISB
    performSearch((celaB210).toUpperCase())
  }, [galerias])
  // B211
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaB211 = "B211";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapB211(filteredResults);
      setPessoasSearchMapB211total(filteredResults.length)

    }
    //BBBIXO O CBMPO DB FUNÇÃO QUE IRB EFETUBR B PESQUISB
    performSearch((celaB211).toUpperCase())
  }, [galerias])

  // B212
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaB212 = "B212";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapB212(filteredResults);
      setPessoasSearchMapB212total(filteredResults.length)

    }
    //BBBIXO O CBMPO DB FUNÇÃO QUE IRB EFETUBR B PESQUISB
    performSearch((celaB212).toUpperCase())
  }, [galerias])

  // B213
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaB213 = "B213";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapB213(filteredResults);
      setPessoasSearchMapB213total(filteredResults.length)

    }
    //BBBIXO O CBMPO DB FUNÇÃO QUE IRB EFETUBR B PESQUISB
    performSearch((celaB213).toUpperCase())
  }, [galerias])

  // B214
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaB214 = "B214";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapB214(filteredResults);
      setPessoasSearchMapB214total(filteredResults.length)

    }
    //BBBIXO O CBMPO DB FUNÇÃO QUE IRB EFETUBR B PESQUISB
    performSearch((celaB214).toUpperCase())
  }, [galerias])

  // B215
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaB215 = "B215";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapB215(filteredResults);
      setPessoasSearchMapB215total(filteredResults.length)

    }
    //BBBIXO O CBMPO DB FUNÇÃO QUE IRB EFETUBR B PESQUISB
    performSearch((celaB215).toUpperCase())
  }, [galerias])

  // B216
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaB216 = "B216";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapB216(filteredResults);
      setPessoasSearchMapB216total(filteredResults.length)

    }
    //BBBIXO O CBMPO DB FUNÇÃO QUE IRB EFETUBR B PESQUISB
    performSearch((celaB216).toUpperCase())
  }, [galerias])


  // B217
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaB217 = "B217";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapB217(filteredResults);
      setPessoasSearchMapB217total(filteredResults.length)

    }
    //BBBIXO O CBMPO DB FUNÇÃO QUE IRB EFETUBR B PESQUISB
    performSearch((celaB217).toUpperCase())
  }, [galerias])

  // B218
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaB218 = "B218";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapB218(filteredResults);
      setPessoasSearchMapB218total(filteredResults.length)

    }
    //BBBIXO O CBMPO DB FUNÇÃO QUE IRB EFETUBR B PESQUISB
    performSearch((celaB218).toUpperCase())
  }, [galerias])

  // B219
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaB219 = "B219";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapB219(filteredResults);
      setPessoasSearchMapB219total(filteredResults.length)

    }
    //BBBIXO O CBMPO DB FUNÇÃO QUE IRB EFETUBR B PESQUISB
    performSearch((celaB219).toUpperCase())
  }, [galerias])

  // B220
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaB220 = "B220";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapB220(filteredResults);
      setPessoasSearchMapB220total(filteredResults.length)

    }
    //BBBIXO O CBMPO DB FUNÇÃO QUE IRB EFETUBR B PESQUISB
    performSearch((celaB220).toUpperCase())
  }, [galerias])

  // B221
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaB221 = "B221";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapB221(filteredResults);
      setPessoasSearchMapB221total(filteredResults.length)

    }
    //BBBIXO O CBMPO DB FUNÇÃO QUE IRB EFETUBR B PESQUISB
    performSearch((celaB221).toUpperCase())
  }, [galerias])

  // B222
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaB222 = "B222";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapB222(filteredResults);
      setPessoasSearchMapB222total(filteredResults.length)

    }
    //BBBIXO O CBMPO DB FUNÇÃO QUE IRB EFETUBR B PESQUISB
    performSearch((celaB222).toUpperCase())
  }, [galerias])

  //C C101
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaC101 = "C101";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapC101(filteredResults);
      setPessoasSearchMapC101total(filteredResults.length)

    }
    //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
    performSearch((celaC101).toUpperCase())
  }, [galerias])

  // C102
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaC102 = "C102";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapC102(filteredResults);
      setPessoasSearchMapC102total(filteredResults.length)

    }
    //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
    performSearch((celaC102).toUpperCase())
  }, [galerias])

  // C103
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaC103 = "C103";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapC103(filteredResults);
      setPessoasSearchMapC103total(filteredResults.length)

    }
    //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
    performSearch((celaC103).toUpperCase())
  }, [galerias])

  // C104
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaC104 = "C104";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapC104(filteredResults);
      setPessoasSearchMapC104total(filteredResults.length)

    }
    //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
    performSearch((celaC104).toUpperCase())
  }, [galerias])
  // C105
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaC105 = "C105";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapC105(filteredResults);
      setPessoasSearchMapC105total(filteredResults.length)

    }
    //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
    performSearch((celaC105).toUpperCase())
  }, [galerias])

  // C106
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaC106 = "C106";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapC106(filteredResults);
      setPessoasSearchMapC106total(filteredResults.length)

    }
    //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
    performSearch((celaC106).toUpperCase())
  }, [galerias])

  // C107
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaC107 = "C107";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapC107(filteredResults);
      setPessoasSearchMapC107total(filteredResults.length)

    }
    //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
    performSearch((celaC107).toUpperCase())
  }, [galerias])


  // C108
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaC108 = "C108";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapC108(filteredResults);
      setPessoasSearchMapC108total(filteredResults.length)

    }
    //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
    performSearch((celaC108).toUpperCase())
  }, [galerias])
  // C109
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaC109 = "C109";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapC109(filteredResults);
      setPessoasSearchMapC109total(filteredResults.length)

    }
    //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
    performSearch((celaC109).toUpperCase())
  }, [galerias])

  // C110
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaC110 = "C110";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapC110(filteredResults);
      setPessoasSearchMapC110total(filteredResults.length)

    }
    //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
    performSearch((celaC110).toUpperCase())
  }, [galerias])
  // C111
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaC111 = "C111";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapC111(filteredResults);
      setPessoasSearchMapC111total(filteredResults.length)

    }
    //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
    performSearch((celaC111).toUpperCase())
  }, [galerias])

  // C112
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaC112 = "C112";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapC112(filteredResults);
      setPessoasSearchMapC112total(filteredResults.length)

    }
    //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
    performSearch((celaC112).toUpperCase())
  }, [galerias])


  // C113
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaC113 = "C113";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapC113(filteredResults);
      setPessoasSearchMapC113total(filteredResults.length)

    }
    //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
    performSearch((celaC113).toUpperCase())
  }, [galerias])

  // C114
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaC114 = "C114";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapC114(filteredResults);
      setPessoasSearchMapC114total(filteredResults.length)

    }
    //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
    performSearch((celaC114).toUpperCase())
  }, [galerias])

  // C115
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaC115 = "C115";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapC115(filteredResults);
      setPessoasSearchMapC115total(filteredResults.length)

    }
    //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
    performSearch((celaC115).toUpperCase())
  }, [galerias])

  // C116
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaC116 = "C116";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapC116(filteredResults);
      setPessoasSearchMapC116total(filteredResults.length)

    }
    //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
    performSearch((celaC116).toUpperCase())
  }, [galerias])


  // C117
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaC117 = "C117";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapC117(filteredResults);
      setPessoasSearchMapC117total(filteredResults.length)

    }
    //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
    performSearch((celaC117).toUpperCase())
  }, [galerias])

  // C118
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaC118 = "C118";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapC118(filteredResults);
      setPessoasSearchMapC118total(filteredResults.length)

    }
    //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
    performSearch((celaC118).toUpperCase())
  }, [galerias])

  // C119
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaC119 = "C119";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapC119(filteredResults);
      setPessoasSearchMapC119total(filteredResults.length)

    }
    //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
    performSearch((celaC119).toUpperCase())
  }, [galerias])

  // C120
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaC120 = "C120";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapC120(filteredResults);
      setPessoasSearchMapC120total(filteredResults.length)

    }
    //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
    performSearch((celaC120).toUpperCase())
  }, [galerias])

  // C121
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaC121 = "C121";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapC121(filteredResults);
      setPessoasSearchMapC121total(filteredResults.length)

    }
    //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
    performSearch((celaC121).toUpperCase())
  }, [galerias])

  // C122
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaC122 = "C122";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapC122(filteredResults);
      setPessoasSearchMapC122total(filteredResults.length)

    }
    //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
    performSearch((celaC122).toUpperCase())
  }, [galerias])
  ///////////////////////////////////////////////////////////////////////


  // C201
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaC201 = "C201";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapC201(filteredResults);
      setPessoasSearchMapC201total(filteredResults.length)

    }
    //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
    performSearch((celaC201).toUpperCase())
  }, [galerias])

  // C202
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaC202 = "C202";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapC202(filteredResults);
      setPessoasSearchMapC202total(filteredResults.length)

    }
    //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
    performSearch((celaC202).toUpperCase())
  }, [galerias])

  // C203
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaC203 = "C203";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapC203(filteredResults);
      setPessoasSearchMapC203total(filteredResults.length)

    }
    //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
    performSearch((celaC203).toUpperCase())
  }, [galerias])

  // C204
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaC204 = "C204";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapC204(filteredResults);
      setPessoasSearchMapC204total(filteredResults.length)

    }
    //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
    performSearch((celaC204).toUpperCase())
  }, [galerias])
  // C205
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaC205 = "C205";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapC205(filteredResults);
      setPessoasSearchMapC205total(filteredResults.length)

    }
    //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
    performSearch((celaC205).toUpperCase())
  }, [galerias])

  // C206
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaC206 = "C206";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapC206(filteredResults);
      setPessoasSearchMapC206total(filteredResults.length)

    }
    //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
    performSearch((celaC206).toUpperCase())
  }, [galerias])

  // C207
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaC207 = "C207";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapC207(filteredResults);
      setPessoasSearchMapC207total(filteredResults.length)

    }
    //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
    performSearch((celaC207).toUpperCase())
  }, [galerias])


  // C208
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaC208 = "C208";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapC208(filteredResults);
      setPessoasSearchMapC208total(filteredResults.length)

    }
    //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
    performSearch((celaC208).toUpperCase())
  }, [galerias])
  // C209
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaC209 = "C209";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapC209(filteredResults);
      setPessoasSearchMapC209total(filteredResults.length)

    }
    //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
    performSearch((celaC209).toUpperCase())
  }, [galerias])

  // C210
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaC210 = "C210";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapC210(filteredResults);
      setPessoasSearchMapC210total(filteredResults.length)

    }
    //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
    performSearch((celaC210).toUpperCase())
  }, [galerias])
  // C211
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaC211 = "C211";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapC211(filteredResults);
      setPessoasSearchMapC211total(filteredResults.length)

    }
    //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
    performSearch((celaC211).toUpperCase())
  }, [galerias])

  // C212
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaC212 = "C212";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapC212(filteredResults);
      setPessoasSearchMapC212total(filteredResults.length)

    }
    //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
    performSearch((celaC212).toUpperCase())
  }, [galerias])

  // C213
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaC213 = "C213";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapC213(filteredResults);
      setPessoasSearchMapC213total(filteredResults.length)

    }
    //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
    performSearch((celaC213).toUpperCase())
  }, [galerias])

  // C214
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaC214 = "C214";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapC214(filteredResults);
      setPessoasSearchMapC214total(filteredResults.length)

    }
    //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
    performSearch((celaC214).toUpperCase())
  }, [galerias])

  // C215
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaC215 = "C215";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapC215(filteredResults);
      setPessoasSearchMapC215total(filteredResults.length)

    }
    //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
    performSearch((celaC215).toUpperCase())
  }, [galerias])

  // C216
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaC216 = "C216";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapC216(filteredResults);
      setPessoasSearchMapC216total(filteredResults.length)

    }
    //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
    performSearch((celaC216).toUpperCase())
  }, [galerias])


  // C217
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaC217 = "C217";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapC217(filteredResults);
      setPessoasSearchMapC217total(filteredResults.length)

    }
    //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
    performSearch((celaC217).toUpperCase())
  }, [galerias])

  // C218
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaC218 = "C218";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapC218(filteredResults);
      setPessoasSearchMapC218total(filteredResults.length)

    }
    //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
    performSearch((celaC218).toUpperCase())
  }, [galerias])

  // C219
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaC219 = "C219";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapC219(filteredResults);
      setPessoasSearchMapC219total(filteredResults.length)

    }
    //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
    performSearch((celaC219).toUpperCase())
  }, [galerias])

  // C220
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaC220 = "C220";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapC220(filteredResults);
      setPessoasSearchMapC220total(filteredResults.length)

    }
    //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
    performSearch((celaC220).toUpperCase())
  }, [galerias])

  // C221
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaC221 = "C221";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapC221(filteredResults);
      setPessoasSearchMapC221total(filteredResults.length)

    }
    //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
    performSearch((celaC221).toUpperCase())
  }, [galerias])

  // C222
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaC222 = "C222";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapC222(filteredResults);
      setPessoasSearchMapC222total(filteredResults.length)

    }
    //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
    performSearch((celaC222).toUpperCase())
  }, [galerias])

  // D201
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaD201 = "D201";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapD201(filteredResults);
      setPessoasSearchMapD201total(filteredResults.length)

    }
    //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
    performSearch((celaD201).toUpperCase())
  }, [galerias])

  // D202
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaD202 = "D202";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapD202(filteredResults);
      setPessoasSearchMapD202total(filteredResults.length)

    }
    //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
    performSearch((celaD202).toUpperCase())
  }, [galerias])

  // D203
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaD203 = "D203";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapD203(filteredResults);
      setPessoasSearchMapD203total(filteredResults.length)

    }
    //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
    performSearch((celaD203).toUpperCase())
  }, [galerias])

  // D204
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaD204 = "D204";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapD204(filteredResults);
      setPessoasSearchMapD204total(filteredResults.length)

    }
    //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
    performSearch((celaD204).toUpperCase())
  }, [galerias])
  // D205
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaD205 = "D205";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapD205(filteredResults);
      setPessoasSearchMapD205total(filteredResults.length)

    }
    //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
    performSearch((celaD205).toUpperCase())
  }, [galerias])

  // D206
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaD206 = "D206";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapD206(filteredResults);
      setPessoasSearchMapD206total(filteredResults.length)

    }
    //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
    performSearch((celaD206).toUpperCase())
  }, [galerias])

  // D207
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaD207 = "D207";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapD207(filteredResults);
      setPessoasSearchMapD207total(filteredResults.length)

    }
    //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
    performSearch((celaD207).toUpperCase())
  }, [galerias])


  // D208
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaD208 = "D208";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapD208(filteredResults);
      setPessoasSearchMapD208total(filteredResults.length)

    }
    //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
    performSearch((celaD208).toUpperCase())
  }, [galerias])
  // D209
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaD209 = "D209";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapD209(filteredResults);
      setPessoasSearchMapD209total(filteredResults.length)

    }
    //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
    performSearch((celaD209).toUpperCase())
  }, [galerias])

  // D210
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaD210 = "D210";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapD210(filteredResults);
      setPessoasSearchMapD210total(filteredResults.length)

    }
    //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
    performSearch((celaD210).toUpperCase())
  }, [galerias])
  // TR201
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaTR201 = "TR201";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapTR201(filteredResults);
      setPessoasSearchMapTR201total(filteredResults.length)

    }
    //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
    performSearch((celaTR201).toUpperCase())
  }, [galerias])


  // TR202
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let celaTR202 = "TR202";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasSearchMapTR202(filteredResults);
      setPessoasSearchMapTR202total(filteredResults.length)

    }
    //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
    performSearch((celaTR202).toUpperCase())
  }, [galerias])



  // DOMICILIAR
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let domiciliar = "DOMICILIAR";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

     
      setPessoasDomiciliartotal(filteredResults.length)

    }
    //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
    performSearch((domiciliar).toUpperCase())
  }, [galerias])


  // HOSPITAL
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let hospital = "HOSPITAL";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasHospitaltotal(filteredResults.length)

    }
    //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
    performSearch((hospital).toUpperCase())
  }, [galerias])


  // HOSPITAL
  useEffect(() => {
    let array = [];
    for (let key in galerias)
      array.push(galerias[key]);
    let pernoite = "PERNOITE";

    function performSearch(searchTerm) {
      let data = array

      let filteredResults = data.filter(item =>
        item.cela.includes(searchTerm)
      );

      setPessoasPernoitetotal(filteredResults.length)

    }
    //CCCIXO O CCMPO DC FUNÇÃO QUE IRC EFETUCR C PESQUISC
    performSearch((pernoite).toUpperCase())
  }, [galerias])






  return (




    <>
      <div className={styles.listaGalerias}>




        <div id="tab1" style={{ marginRight: '20px', cursor: 'pointer' }} onClick={paginaCapa}>
          <b style={ativaBotao === 6 ? { textDecoration: 'none', color: 'green', fontWeight: 'bolder', transition: 'color 0.3s ease' } : { textDecoration: 'none', color: '#333', transition: 'color 0.3s ease' }}><b>CAPA</b></b>
        </div>
        <div id="tab1" style={{ marginRight: '20px', cursor: 'pointer' }} onClick={paginaGaleriaA}>
          <b style={ativaBotao === 1 ? { textDecoration: 'none', color: 'green', fontWeight: 'bolder', transition: 'color 0.3s ease' } : { textDecoration: 'none', color: '#333', transition: 'color 0.3s ease' }}><b>GALERIA (A)</b></b>
        </div>

        <div id="tab2" style={{ marginRight: '20px', cursor: 'pointer' }} onClick={paginaGaleriaB}>
          <b style={ativaBotao === 2 ? { textDecoration: 'none', color: 'green', fontWeight: 'bolder', transition: 'color 0.3s ease' } : { textDecoration: 'none', color: '#333', transition: 'color 0.3s ease' }}><b>GALERIA (B)</b></b>
        </div>
        <div id="tab2" style={{ marginRight: '20px', cursor: 'pointer' }} onClick={paginaGaleriaC}>
          <b style={ativaBotao === 3 ? { textDecoration: 'none', color: 'green', fontWeight: 'bolder', transition: 'color 0.3s ease' } : { textDecoration: 'none', color: '#333', transition: 'color 0.3s ease' }}><b>GALERIA (C)</b></b>
        </div>
        <div id="tab2" style={{ marginRight: '20px', cursor: 'pointer' }} onClick={paginaGaleriaD}>
          <b style={ativaBotao === 4 ? { textDecoration: 'none', color: 'green', fontWeight: 'bolder', transition: 'color 0.3s ease' } : { textDecoration: 'none', color: '#333', transition: 'color 0.3s ease' }}><b>GALERIA (D E TR)</b></b>
        </div>
        <div id="tab2" style={{ marginRight: '20px', cursor: 'pointer' }} onClick={paginaGeral}>
          <b style={ativaBotao === 5 ? { textDecoration: 'none', color: 'green', fontWeight: 'bolder', transition: 'color 0.3s ease' } : { textDecoration: 'none', color: '#333', transition: 'color 0.3s ease' }}><b>GERAL</b></b>
        </div>
        <div id="tab2" style={{ marginRight: '20px', cursor: 'pointer', marginLeft:10,  }} onClick={exportToExcel}>
          <b style={ativaBotao === 5 ? { textDecoration: 'none', color: 'green', fontWeight: 'bolder', transition: 'color 0.3s ease' } : { textDecoration: 'none', color: '#333', transition: 'color 0.3s ease' }}><img src={excel} alt="Logo" height={40} /></b>
        </div>

        {/* Continue com os outros elementos... */}
      </div>

      {(pagina === 6) && <div id='capa'>

        <p className={styles.pulolista}><center><h1 style={{ marginTop: 30, fontFamily: 'policiapenal' }}>CAPA</h1><Button onClick={imprimirConteudo}>IMPRIMIR <FiPrinter />
        </Button></center></p>
        <center><div className="conteudo-para-impressao" id="conteudo-para-impressao" name="conteudo-para-impressao" style={{
          border: '1px solid black',
          width: '210mm',
          height: '300mm',
          margin: '0 auto',
          backgroundColor: "white",
          alignItems: "center",
          justifyContent: 'center',
          justifyItems: 'center'



        }}>
          <br></br><br></br>
          <center> 

            <p><h2><img src={logo} alt="Logo" height={48} /><b> POLICIA PENAL | ES</b> </h2></p>
           <p>CENTRO DE DETENÇÃO PROVISÓRIA DA SERRA (CDPS)</p>
          
            
           
            <small><b>  
            {dia}/{mes}/{ano} às {hora}:{minutosFormatados}</b></small><br></br>
            <p> </p>
          </center>
          <Table bordered style={{ width: '60%', fontSize:14, alignItems:'center', textAlign:'center' }}>
            <thead>
              <tr>
                <th>LOCAL</th>
                <th>QUANTITATIVO</th>
              </tr>
            </thead>
            <tbody>

              <tr>
                <td>GALERIA "A"</td>
                <td>{totalSomaA} </td>
              </tr>
              <tr>
                <td>GALERIA "B"</td>
                <td>{totalSomaB} </td>
              </tr>
              <tr>
                <td>GALERIA "C"</td>
                <td>{totalSomaC} </td>
              </tr>
              <tr>
                <td>GALERIA "D"</td>
                <td>{totalSomaD} </td>
              </tr>
              <tr>
                <td>TRIAGEM</td>
                <td>{totalSomaTR} </td>
              </tr>
              <tr>
                <td> <b>TOTAL NA UP</b></td>
                <td><strong>{totalUp}</strong> </td>
              </tr>


              <tr>
                <td> DOMICILIAR</td>
                <td>{pessoasDomiciliartotal} </td>
              </tr>
              <tr>
                <td>HOSPITAL</td>
                <td>{pessoasHospitaltotal}</td>
              </tr>
              <tr>
                <td>PERNOITE</td>
                <td>{pessoasPernoitetotal}</td>
              </tr>
              <tr>
                <td> <b>TOTAL GERAL</b></td>
                <td><strong>{totalGeral}</strong></td>
              </tr>


            </tbody>
          </Table>
          <table style={{ width: '90%', borderCollapse: 'collapse', border: '1px solid #ddd', fontSize: '10px' }}>
            <thead>
              <tr>
                <th style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }} colspan="4">A</th>
                <th style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }} colspan="4">B</th>
                <th style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }} colspan="4">C</th>
                <th style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }} colspan="2">DTR</th>

              </tr>
            </thead>

            <tbody>

              <tr >
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>A101</b></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapA101total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>A201</b></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapA201total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>B101</b></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapB101total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>B201</b> </td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapB201total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>C101</b> </td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapC101total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>C201</b> </td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapC201total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>D201</b> </td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapD201total}</td>
                
              </tr>
               <tr >
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>A102</b></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapA102total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>A202</b></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapA202total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>B102</b></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapB102total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>B202</b> </td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapB202total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>C102</b> </td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapC102total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>C202</b> </td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapC202total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>D202</b> </td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapD202total}</td>
        
              </tr>
              <tr >
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>A103</b></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapA103total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>A203</b></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapA203total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>B103</b></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapB103total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>B203</b> </td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapB203total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>C103</b> </td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapC103total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>C203</b> </td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapC203total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>D203</b> </td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapD203total}</td>
                
              </tr>
              <tr >
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>A104</b></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapA104total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>A204</b></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapA204total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>B104</b></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapB104total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>B204</b> </td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapB204total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>C104</b> </td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapC104total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>C204</b> </td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapC204total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>D204</b> </td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapD204total}</td>
              
              </tr>
              <tr >
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>A105</b></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapA105total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>A205</b></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapA205total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>B105</b></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapB105total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>B205</b> </td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapB205total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>C105</b> </td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapC105total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>C205</b> </td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapC205total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>D205</b> </td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapD205total}</td>
             
              </tr>
              <tr >
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>A106</b></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapA106total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>A206</b></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapA206total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>B106</b></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapB106total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>B206</b> </td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapB206total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>C106</b> </td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapC106total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>C206</b> </td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapC206total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>D206</b> </td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapD206total}</td>
             
              </tr>
              <tr >
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>A107</b></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapA107total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>A207</b></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapA207total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>B107</b></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapB107total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>B207</b> </td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapB207total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>C107</b> </td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapC107total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>C207</b> </td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapC207total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>D207</b> </td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapD207total}</td>
             
              </tr>
              <tr >
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>A108</b></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapA108total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>A208</b></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapA208total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>B108</b></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapB108total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>B208</b> </td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapB208total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>C108</b> </td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapC108total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>C208</b> </td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapC208total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>D208</b> </td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapD208total}</td>
              
              </tr>
              <tr >
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>A109</b></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapA109total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>A209</b></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapA209total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>B109</b></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapB109total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>B209</b> </td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapB209total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>C109</b> </td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapC109total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>C209</b> </td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapC209total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>D209</b> </td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapD209total}</td>
          
              </tr>
              <tr >
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>A110</b></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapA110total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>A210</b></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapA210total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>B110</b></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapB110total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>B210</b> </td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapB210total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>C110</b> </td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapC110total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>C210</b> </td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapC210total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>D210</b> </td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapD210total}</td>
             
              </tr>
              <tr >
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>A111</b></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapA111total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>A211</b></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapA211total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>B111</b></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapB111total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>B211</b> </td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapB211total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>C111</b> </td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapC111total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>C211</b> </td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapC211total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b></b> </td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}></td>
               
              </tr>
              <tr >
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>A112</b></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapA112total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>A212</b></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapA212total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>B112</b></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapB112total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>B212</b> </td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapB212total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>C112</b> </td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapC112total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>C212</b> </td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapC212total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>TR201</b> </td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapTR201total}</td>
             
              </tr>
              <tr >
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>A113</b></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapA113total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>A213</b></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapA213total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>B113</b></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapB113total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>B213</b> </td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapB213total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>C113</b> </td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapC113total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>C213</b> </td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapC213total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>TR202</b> </td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapTR202total}</td>
             
              </tr>
              <tr >
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>A114</b></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapA114total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>A214</b></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapA214total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>B114</b></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapB114total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>B214</b> </td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapB214total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>C114</b> </td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapC114total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>C214</b> </td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapC214total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b></b> </td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}></td>
             
              </tr>
              <tr >
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>A115</b></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapA115total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>A215</b></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapA215total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>B115</b></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapB115total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>B215</b> </td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapB215total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>C115</b> </td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapC115total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>C215</b> </td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapC215total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b></b> </td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}></td>
            
              </tr>
              <tr >
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>A116</b></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapA116total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>A216</b></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapA216total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>B116</b></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapB116total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>B216</b> </td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapB216total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>C116</b> </td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapC116total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>C216</b> </td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapC216total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b></b> </td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}></td>
               
              </tr>
              <tr >
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>A117</b></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapA117total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>A217</b></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapA217total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>B117</b></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapB117total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>B217</b> </td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapB217total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>C117</b> </td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapC117total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>C217</b> </td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapC217total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b></b> </td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}></td>
                
              </tr>
              <tr >
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>A118</b></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapA118total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>A218</b></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapA218total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>B118</b></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapB118total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>B218</b> </td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapB218total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>C118</b> </td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapC118total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>C218</b> </td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapC218total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b></b> </td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}></td>
                
              </tr>
              <tr >
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b></b></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b></b></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>B119</b></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapB119total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>B219</b> </td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapB219total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>C119</b> </td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapC119total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>C219</b> </td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapC219total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b></b> </td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}></td>
             
              </tr>
              <tr >
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b></b></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b></b></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>B120</b></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapB120total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>B220</b> </td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapB220total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>C120</b> </td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapC120total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>C220</b> </td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapC220total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b></b> </td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}></td>
              
              </tr>
              <tr >
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b></b></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b></b></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>B121</b></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapB121total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>B221</b> </td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapB221total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>C121</b> </td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapC121total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>C221</b> </td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapC221total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b></b> </td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}></td>
       
              </tr>
              <tr >
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b></b></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b></b></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>B122</b></td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapB122total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>B222</b> </td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapB222total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>C122</b> </td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapC122total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b>C222</b> </td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}>{pessoasSearchMapC222total}</td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}><b></b> </td>
                <td style={{ textAlign: 'center', padding: '1px', border: '1px solid #ddd' }}></td>
                
              </tr>
             
             
            


            </tbody>
          </table>

        </div>

        </center>
      </div>}


      {(pagina === 1) && <p className={styles.pulolista}><center><h1 style={{ marginTop: 30, fontFamily: 'policiapenal' }}>GALERIA "A"</h1><Button onClick={imprimirConteudo}>IMPRIMIR <FiPrinter />
      </Button></center></p>}
      {(pagina === 5) && <p className={styles.pulolista}><center><h1 style={{ marginTop: 30, fontFamily: 'policiapenal' }}>CONTAGEM GERAL</h1><Button onClick={imprimirConteudo}>IMPRIMIR <FiPrinter />
      </Button></center></p>}
      {(pagina === 1 || pagina === 5) && <div className="conteudo-para-impressao" id="conteudo-para-impressao" name="conteudo-para-impressao">

        <center> <small style={{ marginTop: -10, }}><b>GALERIA "A" / TOTAL GALERIA ({totalSomaA}) / QUADRANTE A101 a A112 ({totalQuadrante1A}) / QUADRANTE A113 a A118 ({totalQuadrante2A}) - {dia}/{mes}/{ano} às {hora}:{minutosFormatados}</b></small>
        </center>

        <Container style={{ width: '320mm', height: '210mm' }}>
          {/* Cada coluna representa aproximadamente 1/12 da largura da folha A4 */}
          <Row>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>A101 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
              <Celacontagem cela="A101" celaTotal={pessoasSearchMapA101total} celaMap={(pessoasSearchMapA101 && pessoasSearchMapA101)} />
            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>A102 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
              <Celacontagem cela="A102" celaTotal={pessoasSearchMapA102total} celaMap={(pessoasSearchMapA102 && pessoasSearchMapA102)} />
            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>A103 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
              <Celacontagem cela="A103" celaTotal={pessoasSearchMapA103total} celaMap={(pessoasSearchMapA103 && pessoasSearchMapA103)} />

            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>A104 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
              <Celacontagem cela="A104" celaTotal={pessoasSearchMapA104total} celaMap={(pessoasSearchMapA104 && pessoasSearchMapA104)} />

            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>A105 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
              <Celacontagem cela="A105" celaTotal={pessoasSearchMapA105total} celaMap={(pessoasSearchMapA105 && pessoasSearchMapA105)} />


            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>A106 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
              <Celacontagem cela="A106" celaTotal={pessoasSearchMapA106total} celaMap={(pessoasSearchMapA106 && pessoasSearchMapA106)} />

            </Col>

          </Row>
          <Row>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>A107 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
              <Celacontagem cela="A107" celaTotal={pessoasSearchMapA107total} celaMap={(pessoasSearchMapA107 && pessoasSearchMapA107)} />

            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>A108 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>

              <Celacontagem cela="A108" celaTotal={pessoasSearchMapA108total} celaMap={(pessoasSearchMapA108 && pessoasSearchMapA108)} />

            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>A109 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>

              <Celacontagem cela="A109" celaTotal={pessoasSearchMapA109total} celaMap={(pessoasSearchMapA109 && pessoasSearchMapA109)} />

            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>A110 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>

              <Celacontagem cela="A110" celaTotal={pessoasSearchMapA110total} celaMap={(pessoasSearchMapA110 && pessoasSearchMapA110)} />

            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>A111 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
              <Celacontagem cela="A111" celaTotal={pessoasSearchMapA111total} celaMap={(pessoasSearchMapA111 && pessoasSearchMapA111)} />
            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>A112 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
              <Celacontagem cela="A112" celaTotal={pessoasSearchMapA112total} celaMap={(pessoasSearchMapA112 && pessoasSearchMapA112)} />
            </Col>

          </Row>
          <Row>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>A113 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
              <Celacontagem cela="A113" celaTotal={pessoasSearchMapA113total} celaMap={(pessoasSearchMapA113 && pessoasSearchMapA113)} />

            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>A114 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
              <Celacontagem cela="A114" celaTotal={pessoasSearchMapA114total} celaMap={(pessoasSearchMapA114 && pessoasSearchMapA114)} />

            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>A115 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
              <Celacontagem cela="A115" celaTotal={pessoasSearchMapA115total} celaMap={(pessoasSearchMapA115 && pessoasSearchMapA115)} />

            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>A116 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
              <Celacontagem cela="A116" celaTotal={pessoasSearchMapA116total} celaMap={(pessoasSearchMapA116 && pessoasSearchMapA116)} />

            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>A117 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
              <Celacontagem cela="A117" celaTotal={pessoasSearchMapA117total} celaMap={(pessoasSearchMapA117 && pessoasSearchMapA117)} />

            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>A118 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
              <Celacontagem cela="A118" celaTotal={pessoasSearchMapA118total} celaMap={(pessoasSearchMapA118 && pessoasSearchMapA118)} />
            </Col>
          </Row>
          <Row >
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              
            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              
            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              
            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              
            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white', borderRight: 'none' }}><b>OBSERVAÇÕES</b>:</Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white', borderLeft: 'none' }}>
              <p><small>A101 - A112 <b> ( {totalQuadrante1A} ) </b></small></p>
              <p> <small>A113 - A118 <b> ( {totalQuadrante2A} ) </b></small></p>
              <p> <small>TOTAL <b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; ( {totalQuadrante2A + totalQuadrante1A} ) </b></small></p>



            </Col>

          </Row>



        </Container>


      </div>}
      {(pagina === 1 || pagina === 5) && <div className="conteudo-para-impressao" style={{ marginTop: -100 }} id="conteudo-para-impressao" name="conteudo-para-impressao">

        <center> <small style={{ marginTop: -10 }}><b>GALERIA "A" / TOTAL GALERIA ({totalSomaA}) / QUADRANTE A201 a A212 ({totalQuadrante3A}) / QUADRANTE A213 a A218 ({totalQuadrante4A}) - {dia}/{mes}/{ano} às {hora}:{minutosFormatados}</b></small>
        </center>

        <Container style={pagina === 1 ? { width: '320mm', height: '180mm' } : { width: '320mm', height: '210mm' }}>
          {/* Cada coluna representa aproximadamente 1/12 da largura da folha A4 */}
          <Row>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>A201 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
              <Celacontagem cela="A201" celaTotal={pessoasSearchMapA201total} celaMap={(pessoasSearchMapA201 && pessoasSearchMapA201)} />
            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>A202 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
              <Celacontagem cela="A202" celaTotal={pessoasSearchMapA202total} celaMap={(pessoasSearchMapA202 && pessoasSearchMapA202)} />
            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>A203 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
              <Celacontagem cela="A203" celaTotal={pessoasSearchMapA203total} celaMap={(pessoasSearchMapA203 && pessoasSearchMapA203)} />

            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>A204 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
              <Celacontagem cela="A204" celaTotal={pessoasSearchMapA204total} celaMap={(pessoasSearchMapA204 && pessoasSearchMapA204)} />

            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>A205 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
              <Celacontagem cela="A205" celaTotal={pessoasSearchMapA205total} celaMap={(pessoasSearchMapA205 && pessoasSearchMapA205)} />


            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>A206 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
              <Celacontagem cela="A206" celaTotal={pessoasSearchMapA206total} celaMap={(pessoasSearchMapA206 && pessoasSearchMapA206)} />

            </Col>

          </Row>
          <Row>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>A207 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
              <Celacontagem cela="A207" celaTotal={pessoasSearchMapA207total} celaMap={(pessoasSearchMapA207 && pessoasSearchMapA207)} />

            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>A208 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>

              <Celacontagem cela="A208" celaTotal={pessoasSearchMapA208total} celaMap={(pessoasSearchMapA208 && pessoasSearchMapA208)} />

            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>A209 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>

              <Celacontagem cela="A209" celaTotal={pessoasSearchMapA209total} celaMap={(pessoasSearchMapA209 && pessoasSearchMapA209)} />

            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>A210 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>

              <Celacontagem cela="A210" celaTotal={pessoasSearchMapA210total} celaMap={(pessoasSearchMapA210 && pessoasSearchMapA210)} />

            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>A211 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
              <Celacontagem cela="A211" celaTotal={pessoasSearchMapA211total} celaMap={(pessoasSearchMapA211 && pessoasSearchMapA211)} />
            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>A212 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
              <Celacontagem cela="A212" celaTotal={pessoasSearchMapA212total} celaMap={(pessoasSearchMapA212 && pessoasSearchMapA212)} />
            </Col>

          </Row>
          <Row>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>A213 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
              <Celacontagem cela="A213" celaTotal={pessoasSearchMapA213total} celaMap={(pessoasSearchMapA213 && pessoasSearchMapA213)} />

            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>A214 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
              <Celacontagem cela="A214" celaTotal={pessoasSearchMapA214total} celaMap={(pessoasSearchMapA214 && pessoasSearchMapA214)} />

            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>A215 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
              <Celacontagem cela="A215" celaTotal={pessoasSearchMapA215total} celaMap={(pessoasSearchMapA215 && pessoasSearchMapA215)} />

            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>A216 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
              <Celacontagem cela="A216" celaTotal={pessoasSearchMapA216total} celaMap={(pessoasSearchMapA216 && pessoasSearchMapA216)} />

            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>A217 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
              <Celacontagem cela="A217" celaTotal={pessoasSearchMapA217total} celaMap={(pessoasSearchMapA217 && pessoasSearchMapA217)} />

            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>A218 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
              <Celacontagem cela="A218" celaTotal={pessoasSearchMapA218total} celaMap={(pessoasSearchMapA218 && pessoasSearchMapA218)} />
            </Col>
          </Row>
          <Row>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
             
            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              
            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
             
            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
             
            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white', borderRight: 'none' }}><b>OBSERVAÇÕES</b>:</Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white', borderLeft: 'none' }}>
              <p><small>A201 - A212 <b> ( {totalQuadrante3A} ) </b></small></p>
              <p> <small>A213 - A218 <b> ( {totalQuadrante4A} ) </b></small></p>
              <p> <small>TOTAL <b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; ( {totalQuadrante3A + totalQuadrante4A} ) </b></small></p>



            </Col>

          </Row>



        </Container>


      </div>}
      {(pagina === 2) && <p className={styles.pulolista}><center><h1 style={pagina === 2 ? { marginTop: 30, fontFamily: 'policiapenal' } : { marginTop: -60, fontFamily: 'policiapenal' }}>GALERIA "B"</h1><Button onClick={imprimirConteudo}>IMPRIMIR <FiPrinter />
      </Button></center></p>}

      {(pagina === 2 || pagina === 5) && <div className="conteudo-para-impressao" style={pagina === 2 ? { marginTop: 0 } : { marginTop: -100 }} id="conteudo-para-impressao" name="conteudo-para-impressao">


        <center> <small style={{ marginTop: -10 }}><b>GALERIA "B" / TOTAL GALERIA ({totalSomaB}) / QUADRANTE B101 a B112 ({totalQuadrante1B}) / QUADRANTE B113 a B122 ({totalQuadrante2B}) - {dia}/{mes}/{ano} às {hora}:{minutosFormatados}</b></small>
        </center>

        <Container style={{ width: '320mm', height: '210mm' }}>
          {/* Cada coluna representa aproximadamente 1/12 da largura da folha A4 */}
          <Row>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>B101 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
              <Celacontagem cela="B101" celaTotal={pessoasSearchMapB101total} celaMap={(pessoasSearchMapB101 && pessoasSearchMapB101)} />
            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>B102 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
              <Celacontagem cela="B102" celaTotal={pessoasSearchMapB102total} celaMap={(pessoasSearchMapB102 && pessoasSearchMapB102)} />
            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>B103 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
              <Celacontagem cela="B103" celaTotal={pessoasSearchMapB103total} celaMap={(pessoasSearchMapB103 && pessoasSearchMapB103)} />

            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>B104 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
              <Celacontagem cela="B104" celaTotal={pessoasSearchMapB104total} celaMap={(pessoasSearchMapB104 && pessoasSearchMapB104)} />

            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>B105 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
              <Celacontagem cela="B105" celaTotal={pessoasSearchMapB105total} celaMap={(pessoasSearchMapB105 && pessoasSearchMapB105)} />


            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>B106 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
              <Celacontagem cela="B106" celaTotal={pessoasSearchMapB106total} celaMap={(pessoasSearchMapB106 && pessoasSearchMapB106)} />

            </Col>

          </Row>
          <Row>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>B107 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
              <Celacontagem cela="B107" celaTotal={pessoasSearchMapB107total} celaMap={(pessoasSearchMapB107 && pessoasSearchMapB107)} />

            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>B108 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>

              <Celacontagem cela="B108" celaTotal={pessoasSearchMapB108total} celaMap={(pessoasSearchMapB108 && pessoasSearchMapB108)} />

            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>B109 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>

              <Celacontagem cela="B109" celaTotal={pessoasSearchMapB109total} celaMap={(pessoasSearchMapB109 && pessoasSearchMapB109)} />

            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>B110 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>

              <Celacontagem cela="B110" celaTotal={pessoasSearchMapB110total} celaMap={(pessoasSearchMapB110 && pessoasSearchMapB110)} />

            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>B111 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
              <Celacontagem cela="B111" celaTotal={pessoasSearchMapB111total} celaMap={(pessoasSearchMapB111 && pessoasSearchMapB111)} />
            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>B112 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
              <Celacontagem cela="B112" celaTotal={pessoasSearchMapB112total} celaMap={(pessoasSearchMapB112 && pessoasSearchMapB112)} />
            </Col>

          </Row>
          <Row>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>B113 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
              <Celacontagem cela="B113" celaTotal={pessoasSearchMapB113total} celaMap={(pessoasSearchMapB113 && pessoasSearchMapB113)} />

            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>B114 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
              <Celacontagem cela="B114" celaTotal={pessoasSearchMapB114total} celaMap={(pessoasSearchMapB114 && pessoasSearchMapB114)} />

            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>B115 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
              <Celacontagem cela="B115" celaTotal={pessoasSearchMapB115total} celaMap={(pessoasSearchMapB115 && pessoasSearchMapB115)} />

            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>B116 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
              <Celacontagem cela="B116" celaTotal={pessoasSearchMapB116total} celaMap={(pessoasSearchMapB116 && pessoasSearchMapB116)} />

            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>B117 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
              <Celacontagem cela="B117" celaTotal={pessoasSearchMapB117total} celaMap={(pessoasSearchMapB117 && pessoasSearchMapB117)} />

            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>B118 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
              <Celacontagem cela="B118" celaTotal={pessoasSearchMapB118total} celaMap={(pessoasSearchMapB118 && pessoasSearchMapB118)} />
            </Col>
          </Row>
          <Row>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>B119 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
              <Celacontagem cela="B119" celaTotal={pessoasSearchMapB119total} celaMap={(pessoasSearchMapB119 && pessoasSearchMapB119)} />

            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>B120 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
              <Celacontagem cela="B120" celaTotal={pessoasSearchMapB120total} celaMap={(pessoasSearchMapB120 && pessoasSearchMapB120)} />

            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>B121 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
              <Celacontagem cela="B121" celaTotal={pessoasSearchMapB121total} celaMap={(pessoasSearchMapB121 && pessoasSearchMapB121)} />

            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>B122 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
              <Celacontagem cela="B122" celaTotal={pessoasSearchMapB122total} celaMap={(pessoasSearchMapB122 && pessoasSearchMapB122)} />

            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white', borderRight: 'none' }}><b>OBSERVAÇÕES</b>:</Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white', borderLeft: 'none' }}>
              <p><small>B101 - B112 <b> ( {totalQuadrante1B} ) </b></small></p>
              <p> <small>B113 - B122 <b> ( {totalQuadrante2B} ) </b></small></p>
              <p> <small>TOTAL <b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; ( {totalQuadrante2B + totalQuadrante1B} ) </b></small></p>



            </Col>

          </Row>



        </Container>


      </div>}

      {(pagina === 2 || pagina === 5) && <div className="conteudo-para-impressao" style={{ marginTop: -100 }} id="conteudo-para-impressao" name="conteudo-para-impressao">

        <center> <small style={{ marginTop: -10 }}><b>GALERIA "B" / TOTAL GALERIA ({totalSomaB}) / QUADRANTE B201 a B212 ({totalQuadrante3B}) / QUADRANTE B213 a B222 ({totalQuadrante4B}) - {dia}/{mes}/{ano} às {hora}:{minutosFormatados}</b></small>
        </center>

        <Container style={pagina === 2 ? { width: '320mm', height: '180mm' } : { width: '320mm', height: '210mm' }}>
          {/* Cada coluna representa aproximadamente 1/12 da largura da folha A4 */}
          <Row>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>B201 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
              <Celacontagem cela="B201" celaTotal={pessoasSearchMapB201total} celaMap={(pessoasSearchMapB201 && pessoasSearchMapB201)} />
            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>B202 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
              <Celacontagem cela="B202" celaTotal={pessoasSearchMapB202total} celaMap={(pessoasSearchMapB202 && pessoasSearchMapB202)} />
            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>B203 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
              <Celacontagem cela="B203" celaTotal={pessoasSearchMapB203total} celaMap={(pessoasSearchMapB203 && pessoasSearchMapB203)} />

            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>B204 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
              <Celacontagem cela="B204" celaTotal={pessoasSearchMapB204total} celaMap={(pessoasSearchMapB204 && pessoasSearchMapB204)} />

            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>B205 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
              <Celacontagem cela="B205" celaTotal={pessoasSearchMapB205total} celaMap={(pessoasSearchMapB205 && pessoasSearchMapB205)} />


            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>B206 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
              <Celacontagem cela="B206" celaTotal={pessoasSearchMapB206total} celaMap={(pessoasSearchMapB206 && pessoasSearchMapB206)} />

            </Col>

          </Row>
          <Row>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>B207 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
              <Celacontagem cela="B207" celaTotal={pessoasSearchMapB207total} celaMap={(pessoasSearchMapB207 && pessoasSearchMapB207)} />

            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>B208 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>

              <Celacontagem cela="B208" celaTotal={pessoasSearchMapB208total} celaMap={(pessoasSearchMapB208 && pessoasSearchMapB208)} />

            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>B209 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>

              <Celacontagem cela="B209" celaTotal={pessoasSearchMapB209total} celaMap={(pessoasSearchMapB209 && pessoasSearchMapB209)} />

            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>B210 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>

              <Celacontagem cela="B210" celaTotal={pessoasSearchMapB210total} celaMap={(pessoasSearchMapB210 && pessoasSearchMapB210)} />

            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>B211 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
              <Celacontagem cela="B211" celaTotal={pessoasSearchMapB211total} celaMap={(pessoasSearchMapB211 && pessoasSearchMapB211)} />
            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>B212 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
              <Celacontagem cela="B212" celaTotal={pessoasSearchMapB212total} celaMap={(pessoasSearchMapB212 && pessoasSearchMapB212)} />
            </Col>

          </Row>
          <Row>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>B213 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
              <Celacontagem cela="B213" celaTotal={pessoasSearchMapB213total} celaMap={(pessoasSearchMapB213 && pessoasSearchMapB213)} />

            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>B214 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
              <Celacontagem cela="B214" celaTotal={pessoasSearchMapB214total} celaMap={(pessoasSearchMapB214 && pessoasSearchMapB214)} />

            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>B215 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
              <Celacontagem cela="B215" celaTotal={pessoasSearchMapB215total} celaMap={(pessoasSearchMapB215 && pessoasSearchMapB215)} />

            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>B216 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
              <Celacontagem cela="B216" celaTotal={pessoasSearchMapB216total} celaMap={(pessoasSearchMapB216 && pessoasSearchMapB216)} />

            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>B217 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
              <Celacontagem cela="B217" celaTotal={pessoasSearchMapB217total} celaMap={(pessoasSearchMapB217 && pessoasSearchMapB217)} />

            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>B218 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
              <Celacontagem cela="B218" celaTotal={pessoasSearchMapB218total} celaMap={(pessoasSearchMapB218 && pessoasSearchMapB218)} />
            </Col>
          </Row>
          <Row>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>B219 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
              <Celacontagem cela="B219" celaTotal={pessoasSearchMapB219total} celaMap={(pessoasSearchMapB219 && pessoasSearchMapB219)} />

            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>B220 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
              <Celacontagem cela="B220" celaTotal={pessoasSearchMapB220total} celaMap={(pessoasSearchMapB220 && pessoasSearchMapB220)} />

            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>B221 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
              <Celacontagem cela="B221" celaTotal={pessoasSearchMapB221total} celaMap={(pessoasSearchMapB221 && pessoasSearchMapB221)} />

            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>B222 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
              <Celacontagem cela="B222" celaTotal={pessoasSearchMapB222total} celaMap={(pessoasSearchMapB222 && pessoasSearchMapB222)} />

            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white', borderRight: 'none' }}><b>OBSERVAÇÕES</b>:</Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white', borderLeft: 'none' }}>
              <p><small>B201 - B212 <b> ( {totalQuadrante3B} ) </b></small></p>
              <p> <small>B213 - B222 <b> ( {totalQuadrante4B} ) </b></small></p>
              <p> <small>TOTAL <b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; ( {totalQuadrante3B + totalQuadrante4B} ) </b></small></p>



            </Col>

          </Row>



        </Container>


      </div>}
      {(pagina === 3) && <p className={styles.pulolista}><center><h1 style={pagina === 3 ? { marginTop: 30, fontFamily: 'policiapenal' } : { marginTop: -60, fontFamily: 'policiapenal' }}>GALERIA "C"</h1><Button onClick={imprimirConteudo}>IMPRIMIR <FiPrinter />
      </Button></center></p>}

      {(pagina === 3 || pagina === 5) && <div className="conteudo-para-impressao" style={pagina === 3 ? { marginTop: 0 } : { marginTop: -100 }} id="conteudo-para-impressao" name="conteudo-para-impressao">

        <center> <small style={{ marginTop: -10 }}><b>GALERIA "C" / TOTAL GALERIA ({totalSomaC}) / QUADRANTE C101 a C112 ({totalQuadrante1C}) / QUADRANTE C113 a C122 ({totalQuadrante2C}) - {dia}/{mes}/{ano} às {hora}:{minutosFormatados}</b></small>
        </center>

        <Container style={{ width: '320mm', height: '210mm' }}>
          {/* Cada coluna representa aproximadamente 1/12 da largura da folha A4 */}
          <Row>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>C101 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
              <Celacontagem cela="C101" celaTotal={pessoasSearchMapC101total} celaMap={(pessoasSearchMapC101 && pessoasSearchMapC101)} />
            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>C102 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
              <Celacontagem cela="C102" celaTotal={pessoasSearchMapC102total} celaMap={(pessoasSearchMapC102 && pessoasSearchMapC102)} />
            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>C103 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
              <Celacontagem cela="C103" celaTotal={pessoasSearchMapC103total} celaMap={(pessoasSearchMapC103 && pessoasSearchMapC103)} />

            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>C104 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
              <Celacontagem cela="C104" celaTotal={pessoasSearchMapC104total} celaMap={(pessoasSearchMapC104 && pessoasSearchMapC104)} />

            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>C105 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
              <Celacontagem cela="C105" celaTotal={pessoasSearchMapC105total} celaMap={(pessoasSearchMapC105 && pessoasSearchMapC105)} />


            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>C106 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
              <Celacontagem cela="C106" celaTotal={pessoasSearchMapC106total} celaMap={(pessoasSearchMapC106 && pessoasSearchMapC106)} />

            </Col>

          </Row>
          <Row>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>C107 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
              <Celacontagem cela="C107" celaTotal={pessoasSearchMapC107total} celaMap={(pessoasSearchMapC107 && pessoasSearchMapC107)} />

            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>C108 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>

              <Celacontagem cela="C108" celaTotal={pessoasSearchMapC108total} celaMap={(pessoasSearchMapC108 && pessoasSearchMapC108)} />

            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>C109 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>

              <Celacontagem cela="C109" celaTotal={pessoasSearchMapC109total} celaMap={(pessoasSearchMapC109 && pessoasSearchMapC109)} />

            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>C110 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>

              <Celacontagem cela="C110" celaTotal={pessoasSearchMapC110total} celaMap={(pessoasSearchMapC110 && pessoasSearchMapC110)} />

            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>C111 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
              <Celacontagem cela="C111" celaTotal={pessoasSearchMapC111total} celaMap={(pessoasSearchMapC111 && pessoasSearchMapC111)} />
            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>C112 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
              <Celacontagem cela="C112" celaTotal={pessoasSearchMapC112total} celaMap={(pessoasSearchMapC112 && pessoasSearchMapC112)} />
            </Col>

          </Row>
          <Row>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>C113 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
              <Celacontagem cela="C113" celaTotal={pessoasSearchMapC113total} celaMap={(pessoasSearchMapC113 && pessoasSearchMapC113)} />

            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>C114 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
              <Celacontagem cela="C114" celaTotal={pessoasSearchMapC114total} celaMap={(pessoasSearchMapC114 && pessoasSearchMapC114)} />

            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>C115 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
              <Celacontagem cela="C115" celaTotal={pessoasSearchMapC115total} celaMap={(pessoasSearchMapC115 && pessoasSearchMapC115)} />

            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>C116 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
              <Celacontagem cela="C116" celaTotal={pessoasSearchMapC116total} celaMap={(pessoasSearchMapC116 && pessoasSearchMapC116)} />

            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>C117 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
              <Celacontagem cela="C117" celaTotal={pessoasSearchMapC117total} celaMap={(pessoasSearchMapC117 && pessoasSearchMapC117)} />

            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>C118 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
              <Celacontagem cela="C118" celaTotal={pessoasSearchMapC118total} celaMap={(pessoasSearchMapC118 && pessoasSearchMapC118)} />
            </Col>
          </Row>
          <Row>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>C119 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
              <Celacontagem cela="C119" celaTotal={pessoasSearchMapC119total} celaMap={(pessoasSearchMapC119 && pessoasSearchMapC119)} />

            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>C120 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
              <Celacontagem cela="C120" celaTotal={pessoasSearchMapC120total} celaMap={(pessoasSearchMapC120 && pessoasSearchMapC120)} />

            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>C121 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
              <Celacontagem cela="C121" celaTotal={pessoasSearchMapC121total} celaMap={(pessoasSearchMapC121 && pessoasSearchMapC121)} />

            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>C122 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
              <Celacontagem cela="C122" celaTotal={pessoasSearchMapC122total} celaMap={(pessoasSearchMapC122 && pessoasSearchMapC122)} />

            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white', borderRight: 'none' }}><b>OBSERVAÇÕES</b>:</Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white', borderLeft: 'none' }}>
              <p><small>C101 - C112 <b> ( {totalQuadrante1C} ) </b></small></p>
              <p> <small>C113 - C122 <b> ( {totalQuadrante2C} ) </b></small></p>
              <p> <small>TOTAL <b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; ( {totalQuadrante1C + totalQuadrante2C} ) </b></small></p>



            </Col>

          </Row>



        </Container>


      </div>}
      {(pagina === 3 || pagina === 5) && <div className="conteudo-para-impressao" style={{ marginTop: -100 }} id="conteudo-para-impressao" name="conteudo-para-impressao">

        <center> <small style={{ marginTop: -10 }}><b>GALERIA "C" / TOTAL GALERIA ({totalSomaC}) / QUADRANTE C201 a C212 ({totalQuadrante3C}) / QUADRANTE C213 a C222 ({totalQuadrante4C}) - {dia}/{mes}/{ano} às {hora}:{minutosFormatados}</b></small>
        </center>

        <Container style={pagina === 3 ? { width: '320mm', height: '180mm' } : { width: '320mm', height: '210mm' }}>
          {/* Cada coluna representa aproximadamente 1/12 da largura da folha A4 */}
          <Row>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>C201 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
              <Celacontagem cela="C201" celaTotal={pessoasSearchMapC201total} celaMap={(pessoasSearchMapC201 && pessoasSearchMapC201)} />
            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>C202 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
              <Celacontagem cela="C202" celaTotal={pessoasSearchMapC202total} celaMap={(pessoasSearchMapC202 && pessoasSearchMapC202)} />
            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>C203 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
              <Celacontagem cela="C203" celaTotal={pessoasSearchMapC203total} celaMap={(pessoasSearchMapC203 && pessoasSearchMapC203)} />

            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>C204 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
              <Celacontagem cela="C204" celaTotal={pessoasSearchMapC204total} celaMap={(pessoasSearchMapC204 && pessoasSearchMapC204)} />

            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>C205 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
              <Celacontagem cela="C205" celaTotal={pessoasSearchMapC205total} celaMap={(pessoasSearchMapC205 && pessoasSearchMapC205)} />


            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>C206 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
              <Celacontagem cela="C206" celaTotal={pessoasSearchMapC206total} celaMap={(pessoasSearchMapC206 && pessoasSearchMapC206)} />

            </Col>

          </Row>
          <Row>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>C207 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
              <Celacontagem cela="C207" celaTotal={pessoasSearchMapC207total} celaMap={(pessoasSearchMapC207 && pessoasSearchMapC207)} />

            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>C208 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>

              <Celacontagem cela="C208" celaTotal={pessoasSearchMapC208total} celaMap={(pessoasSearchMapC208 && pessoasSearchMapC208)} />

            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>C209 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>

              <Celacontagem cela="C209" celaTotal={pessoasSearchMapC209total} celaMap={(pessoasSearchMapC209 && pessoasSearchMapC209)} />

            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>C210 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>

              <Celacontagem cela="C210" celaTotal={pessoasSearchMapC210total} celaMap={(pessoasSearchMapC210 && pessoasSearchMapC210)} />

            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>C211 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
              <Celacontagem cela="C211" celaTotal={pessoasSearchMapC211total} celaMap={(pessoasSearchMapC211 && pessoasSearchMapC211)} />
            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>C212 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
              <Celacontagem cela="C212" celaTotal={pessoasSearchMapC212total} celaMap={(pessoasSearchMapC212 && pessoasSearchMapC212)} />
            </Col>

          </Row>
          <Row>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>C213 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
              <Celacontagem cela="C213" celaTotal={pessoasSearchMapC213total} celaMap={(pessoasSearchMapC213 && pessoasSearchMapC213)} />

            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>C214 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
              <Celacontagem cela="C214" celaTotal={pessoasSearchMapC214total} celaMap={(pessoasSearchMapC214 && pessoasSearchMapC214)} />

            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>C215 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
              <Celacontagem cela="C215" celaTotal={pessoasSearchMapC215total} celaMap={(pessoasSearchMapC215 && pessoasSearchMapC215)} />

            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>C216 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
              <Celacontagem cela="C216" celaTotal={pessoasSearchMapC216total} celaMap={(pessoasSearchMapC216 && pessoasSearchMapC216)} />

            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>C217 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
              <Celacontagem cela="C217" celaTotal={pessoasSearchMapC217total} celaMap={(pessoasSearchMapC217 && pessoasSearchMapC217)} />

            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>C218 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
              <Celacontagem cela="C218" celaTotal={pessoasSearchMapC218total} celaMap={(pessoasSearchMapC218 && pessoasSearchMapC218)} />
            </Col>
          </Row>
          <Row>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>C219 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
              <Celacontagem cela="C219" celaTotal={pessoasSearchMapC219total} celaMap={(pessoasSearchMapC219 && pessoasSearchMapC219)} />

            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>C220 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
              <Celacontagem cela="C220" celaTotal={pessoasSearchMapC220total} celaMap={(pessoasSearchMapC220 && pessoasSearchMapC220)} />

            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>C221 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
              <Celacontagem cela="C221" celaTotal={pessoasSearchMapC221total} celaMap={(pessoasSearchMapC221 && pessoasSearchMapC221)} />

            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>C222 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
              <Celacontagem cela="C222" celaTotal={pessoasSearchMapC222total} celaMap={(pessoasSearchMapC222 && pessoasSearchMapC222)} />

            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white', borderRight: 'none' }}><b>OBSERVAÇÕES</b>:</Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white', borderLeft: 'none' }}>
              <p><small>C201 - C212 <b> ( {totalQuadrante3C} ) </b></small></p>
              <p> <small>C213 - C222 <b> ( {totalQuadrante4C} ) </b></small></p>
              <p> <small>TOTAL <b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; ( {totalQuadrante3C + totalQuadrante4C} ) </b></small></p>



            </Col>

          </Row>



        </Container>


      </div>}
      {(pagina === 4) &&

        <p className={styles.pulolista}><center>

          <h1 style={pagina === 4 ? { marginTop: 30, fontFamily: 'policiapenal' } : { marginTop: -80, fontFamily: 'policiapenal' }}>

            GALERIA "D" E "TR"

          </h1><Button onClick={imprimirConteudo}>IMPRIMIR <FiPrinter />
          </Button>

        </center>
        </p>}

      {(pagina === 4 || pagina === 5) && <div className="conteudo-para-impressao" style={pagina === 4 ? { marginTop: 0 } : { marginTop: -100 }}
        id="conteudo-para-impressao" name="conteudo-para-impressao">

        <center> <small style={{ marginTop: -10, marginBottom: -50 }}><b>GALERIA "D" E "TR" / TOTAL GALERIA "D" ({totalSomaD}) / TOTAL TRIAGEM ({totalSomaTR}) - {dia}/{mes}/{ano} às {hora}:{minutosFormatados}</b></small>
        </center>

        <Container style={pagina === 4 ? { width: '320mm', height: '180mm' } : { width: '320mm', height: '180mm' }}>
          {/* Cada coluna representa aproximadamente 1/12 da largura da folha A4 */}
          <Row>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>D201 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
              <Celacontagem cela="D201" celaTotal={pessoasSearchMapD201total} celaMap={(pessoasSearchMapD201 && pessoasSearchMapD201)} />
            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>D202 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
              <Celacontagem cela="D202" celaTotal={pessoasSearchMapD202total} celaMap={(pessoasSearchMapD202 && pessoasSearchMapD202)} />
            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>D203 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
              <Celacontagem cela="D203" celaTotal={pessoasSearchMapD203total} celaMap={(pessoasSearchMapD203 && pessoasSearchMapD203)} />

            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>D204 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
              <Celacontagem cela="D204" celaTotal={pessoasSearchMapD204total} celaMap={(pessoasSearchMapD204 && pessoasSearchMapD204)} />

            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>D205 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
              <Celacontagem cela="D205" celaTotal={pessoasSearchMapD205total} celaMap={(pessoasSearchMapD205 && pessoasSearchMapD205)} />


            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>D206 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
              <Celacontagem cela="D206" celaTotal={pessoasSearchMapD206total} celaMap={(pessoasSearchMapD206 && pessoasSearchMapD206)} />

            </Col>

          </Row>
          <Row>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>D207 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
              <Celacontagem cela="D207" celaTotal={pessoasSearchMapD207total} celaMap={(pessoasSearchMapD207 && pessoasSearchMapD207)} />

            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>D208 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>

              <Celacontagem cela="D208" celaTotal={pessoasSearchMapD208total} celaMap={(pessoasSearchMapD208 && pessoasSearchMapD208)} />

            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>D209 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>

              <Celacontagem cela="D209" celaTotal={pessoasSearchMapD209total} celaMap={(pessoasSearchMapD209 && pessoasSearchMapD209)} />

            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>
              <center><b>D210 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>

              <Celacontagem cela="D210" celaTotal={pessoasSearchMapD210total} celaMap={(pessoasSearchMapD210 && pessoasSearchMapD210)} />

            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>



            </Col>
            <Col style={{ width: '28mm', height: '45mm', border: '1px solid black', backgroundColor: 'white' }}>



            </Col>


          </Row>
          <Row>


            <Col style={{ width: '28mm', height: '85mm', border: '1px solid black', backgroundColor: 'white', marginBottom: 0 }}>
              <center><b>TR201 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
              <Celacontagem cela="TR201" celaTotal={pessoasSearchMapTR201total} celaMap={(pessoasSearchMapTR201 && pessoasSearchMapTR201)} />

            </Col>
            <Col style={{ width: '28mm', height: '85mm', border: '1px solid black', backgroundColor: 'white', marginBottom: 0 }}>
              <center><b>TR202 (&nbsp;&nbsp;&nbsp;&nbsp;)</b></center>
              <Celacontagem cela="TR202" celaTotal={pessoasSearchMapTR202total} celaMap={(pessoasSearchMapTR202 && pessoasSearchMapTR202)} />

            </Col>
            <Col style={{ width: '56mm', height: '85mm', border: '1px solid black', backgroundColor: 'white', marginBottom: 0 }}>
              <p><small>D201 - D206 <b> ( {totalQuadrante1D} ) </b></small></p>
              <p> <small>D207 - D210 <b> ( {totalQuadrante2D} ) </b></small></p>
              <p> <small>TRIAGEM &nbsp;&nbsp;&nbsp;&nbsp; <b> ( {pessoasSearchMapTR202total + pessoasSearchMapTR201total} ) </b></small></p>
              <p> <small>TOTAL <b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; ( {totalQuadrante1D + totalQuadrante2D + pessoasSearchMapTR202total + pessoasSearchMapTR201total} ) </b></small></p>
            </Col>
          </Row>
        </Container>


      </div>}
    </>

  )
}

export default Contagem