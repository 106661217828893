// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";


// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBAIqWHhFYiCfnVNN-dwG9n4SmQ-Fvokhk",
  authDomain: "ppcdps-cd520.firebaseapp.com",
  projectId: "ppcdps-cd520",
  storageBucket: "ppcdps-cd520.appspot.com",
  messagingSenderId: "586666786014",
  appId: "1:586666786014:web:9e33eda0d1f9b55b64164f"
};
// Initialize Firebase
export const app = initializeApp(firebaseConfig);

export const storage = getStorage(app)

const db = getFirestore(app);

export { db };



